import ringSizes from '@/data/ringSizes.json'
import currencies from '@/data/currencies.json'

export function useUtils() {
  const { $i18n } = useNuxtApp()
  const ringSizesCountries = ringSizes.countries.map((c) => ({
    ...c,
    name: $i18n.t(c.shortname, c.name),
  }))

  const getRingSizeCountriesData = () => {
    return ringSizesCountries
  }

  const getLanguages = async (LanguageId, CountryId, CurrencyId) => {
    const api = '/api/v1/website/languages-for-header'
    const body = {
      LanguageId,
      CountryId,
      CurrencyId,
      Uri: 'https://www.77diamonds.com/',
    }
    try {
      return await $fetch(api, {
        method: 'POST',
        body,
      })
    } catch (e) {
      console.log(body, e)
      throw e
    }
  }
  const getCurrencies = async () => {
    try {
      return await $fetch('/api/v1/accounting/currencies')
    } catch (e) {
      console.log(e)
      throw e
    }
  }
  const getCountries = async () => {
    try {
      return await $fetch('/api/v1/logistics/countries')
    } catch (e) {
      console.log(e)
      throw e
    }
  }
  const getCompany = async (countryId) => {
    try {
      return await $fetch(`/api/v1/logistics/country-company?countryId=${countryId}`)
    } catch (e) {
      console.log(e)
      throw e
    }
  }
  const getPhoneNumbers = async () => {
    try {
      return await $fetch('/api/v1/website/phone-numbers')
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const getCurrencySymbol = (currencyId) => {
    const currency = currencies[currencyId]
    return currency ? currency : ''
  }

  return {
    getRingSizeCountriesData,
    getCurrencies,
    getCountries,
    getCompany,
    getLanguages,
    getPhoneNumbers,
    getCurrencySymbol,
  }
}
