export const PUBLIC_PAGES_ROUTES = {
  CHECKOUT: '/checkout',
  CONTACT: '/contact',
  DELIVERY_AND_RETURNS: '/delivery-and-returns',
  REGISTER: '/register',
  SHOWROOMS: '/showrooms',
}

export const PRIVATE_PAGES_ROUTES = {
  ADDRESS: '/account/addresses',
  ORDERS: '/account/orders',
  PRIVACY: '/account/privacy',
  PROFILE: '/account/profile',
}

export const AUTH_PAGES_ROUTES = {
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  LOGOUT: '/logout',
}

export const POST_PAYMENT_PAGES_ROUTES = {
  CARD: '/payments-return/card',
  FAILED: '/failed.aspx',
  PAYPAL: '/paypal-return',
  SPLITIT: '/payments-return/splitit',
  SUCCESS: '/success.aspx',
}

export const POST_PAYMENT_PAGES_ROUTES_ID = {
  [POST_PAYMENT_PAGES_ROUTES.PAYPAL]: 242,
}
