import { fetchTranslation } from '~/api/translations'

type PageId = string | number
type LanguageCode = string

export const useSetupTranslations = async () => {
  const nuxtApp = useNuxtApp()

  const handleUpdateTranslations = (languageCode: LanguageCode, newTranslations) =>
    nuxtApp.$i18n.setLocaleMessage(languageCode, newTranslations)

  const setupTranslationsByPageId = async (pageId: PageId, languageCode: LanguageCode) => {
    try {
      const newPageTranslations = await fetchTranslation({ id: pageId, languageCode })

      if (!newPageTranslations) {
        console.error('No translation found for', `pageId:${pageId}, localeCode: ${languageCode}`)
        return
      }

      const currentTranslations = nuxtApp.$i18n.getLocaleMessage(languageCode)
      const mergedTranslations = { ...currentTranslations, ...newPageTranslations }

      handleUpdateTranslations(languageCode, mergedTranslations)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    setupTranslationsByPageId,
  }
}
