
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46shop_93zjKNText9jMeta } from "/src/pages/[...shop].vue?macro=true";
import { default as addressesIGZCkwNaoqMeta } from "/src/pages/account/addresses.vue?macro=true";
import { default as ordersF1jXyywrUuMeta } from "/src/pages/account/orders.vue?macro=true";
import { default as privacyaTLU6InpJAMeta } from "/src/pages/account/privacy.vue?macro=true";
import { default as profile4xkbZgafKZMeta } from "/src/pages/account/profile.vue?macro=true";
import { default as bespoke_45jewellery4QrHxVP7J4Meta } from "/src/pages/bespoke-jewellery.vue?macro=true";
import { default as bespoke_45standout_45pieces0xvUJlJ6VkMeta } from "/src/pages/bespoke-standout-pieces.vue?macro=true";
import { default as index4E3N5yzW7cMeta } from "/src/pages/checkout/index.vue?macro=true";
import { default as cushion_45cut_45diamondsMjTpfcAnoCMeta } from "/src/pages/contour/cushion-cut-diamonds.vue?macro=true";
import { default as index6DYUkaVRdpMeta } from "/src/pages/contour/index.vue?macro=true";
import { default as contact71Rz830P7VMeta } from "/src/pages/customer-care/contact.vue?macro=true";
import { default as customer_45care9rqGcfFi8SMeta } from "/src/pages/customer-care/customer-care.vue?macro=true";
import { default as delivery_45and_45returnsSmuhRzs1mpMeta } from "/src/pages/customer-care/delivery-and-returns.vue?macro=true";
import { default as faqAaQGpbjedXMeta } from "/src/pages/customer-care/faq.vue?macro=true";
import { default as finance_45calculator0UvxeF1EDXMeta } from "/src/pages/customer-care/finance-calculator.vue?macro=true";
import { default as terms_45conditionsMMKCzXZeIIMeta } from "/src/pages/customer-care/terms-conditions.vue?macro=true";
import { default as customer_45careOdTE0K9no0Meta } from "/src/pages/customer-care.vue?macro=true";
import { default as diamond_45jewelleryaTnquFRy8QMeta } from "/src/pages/diamond-jewellery.vue?macro=true";
import { default as diamondsZSRK9W2p7zMeta } from "/src/pages/diamonds.vue?macro=true";
import { default as _77diamonds_45press1HaEIkx1wYMeta } from "/src/pages/education/diamond-education/77diamonds-press.vue?macro=true";
import { default as about_4577diamondsw3d5Fwoq4bMeta } from "/src/pages/education/diamond-education/about-77diamonds.vue?macro=true";
import { default as anatomy_45of_45a_45diamondKDwHf7DGzNMeta } from "/src/pages/education/diamond-education/anatomy-of-a-diamond.vue?macro=true";
import { default as awards_45detailsxMKpp7x4pjMeta } from "/src/pages/education/diamond-education/awards-details.vue?macro=true";
import { default as awardsYoSh66TkpzMeta } from "/src/pages/education/diamond-education/awards.vue?macro=true";
import { default as canadian_45diamonds2VOU9iCF2pMeta } from "/src/pages/education/diamond-education/canadian-diamonds.vue?macro=true";
import { default as careershFQmXK4dYZMeta } from "/src/pages/education/diamond-education/careers.vue?macro=true";
import { default as clarity_45enhanced_45diamondscV4P7me4guMeta } from "/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue?macro=true";
import { default as compare_45diamond_45certificatesxEsX9RG1oAMeta } from "/src/pages/education/diamond-education/compare-diamond-certificates.vue?macro=true";
import { default as cost6ozuBJSlf2Meta } from "/src/pages/education/diamond-education/cost.vue?macro=true";
import { default as diamond_45buying_45guideGZh1b8WPHiMeta } from "/src/pages/education/diamond-education/diamond-buying-guide.vue?macro=true";
import { default as diamond_45carat_45weighta3NL3YESYyMeta } from "/src/pages/education/diamond-education/diamond-carat-weight.vue?macro=true";
import { default as diamond_45certificatesFor2vqS7ZEMeta } from "/src/pages/education/diamond-education/diamond-certificates.vue?macro=true";
import { default as diamond_45clarity_45expert_45adviceUDhCLWgrQ3Meta } from "/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue?macro=true";
import { default as diamond_45clarity_45gradingVaHtQ0Cum7Meta } from "/src/pages/education/diamond-education/diamond-clarity-grading.vue?macro=true";
import { default as diamond_45clarity6TuJiBiLm8Meta } from "/src/pages/education/diamond-education/diamond-clarity.vue?macro=true";
import { default as diamond_45colourZxx2jRBd2MMeta } from "/src/pages/education/diamond-education/diamond-colour.vue?macro=true";
import { default as diamond_45cut_45gradingAmAANIKe6JMeta } from "/src/pages/education/diamond-education/diamond-cut-grading.vue?macro=true";
import { default as diamond_45cut_45process1c1Q83k8V5Meta } from "/src/pages/education/diamond-education/diamond-cut-process.vue?macro=true";
import { default as diamond_45cut7O4tfrzeGnMeta } from "/src/pages/education/diamond-education/diamond-cut.vue?macro=true";
import { default as diamond_45fluorescence78IftR77O9Meta } from "/src/pages/education/diamond-education/diamond-fluorescence.vue?macro=true";
import { default as diamond_45grading_45labsFUVIJ3Cms8Meta } from "/src/pages/education/diamond-education/diamond-grading-labs.vue?macro=true";
import { default as diamond_45inclusionsFZ76RqsMSyMeta } from "/src/pages/education/diamond-education/diamond-inclusions.vue?macro=true";
import { default as diamond_45shapesh5NiDRFuzeMeta } from "/src/pages/education/diamond-education/diamond-shapes.vue?macro=true";
import { default as engagement_45buying_45guideu9ZaZfmYrTMeta } from "/src/pages/education/diamond-education/engagement-buying-guide.vue?macro=true";
import { default as ethical_45diamonds92QNeSVTOgMeta } from "/src/pages/education/diamond-education/ethical-diamonds.vue?macro=true";
import { default as fancy_45coloured_45diamondsV32sAu20k5Meta } from "/src/pages/education/diamond-education/fancy-coloured-diamonds.vue?macro=true";
import { default as hearts_45arrowswfOY44xKKxMeta } from "/src/pages/education/diamond-education/hearts-arrows.vue?macro=true";
import { default as how_45are_45diamonds_45cutpj69rrIjOvMeta } from "/src/pages/education/diamond-education/how-are-diamonds-cut.vue?macro=true";
import { default as how_45are_45diamonds_45formed5C6zMSF9GtMeta } from "/src/pages/education/diamond-education/how-are-diamonds-formed.vue?macro=true";
import { default as indexEyNi2vxOyuMeta } from "/src/pages/education/diamond-education/index.vue?macro=true";
import { default as our_45storyVpRIh9HvHkMeta } from "/src/pages/education/diamond-education/our-story.vue?macro=true";
import { default as ring_45size_45chartEQaQIyTSabMeta } from "/src/pages/education/diamond-education/ring-size-chart.vue?macro=true";
import { default as asscher_45cut_45diamondsq10W5OJc38Meta } from "/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue?macro=true";
import { default as cushion_45cut_45diamondsu9bB9UWG2aMeta } from "/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue?macro=true";
import { default as emerald_45cut_45diamondsUjVMFP8BVWMeta } from "/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue?macro=true";
import { default as heart_45cut_45diamondsO0iXVVR7roMeta } from "/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue?macro=true";
import { default as marquise_45cut_45diamondswGUzXwhTvgMeta } from "/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue?macro=true";
import { default as oval_45cut_45diamondsWKywHggYmjMeta } from "/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue?macro=true";
import { default as pear_45shape_45diamondsjNfS1pVLPrMeta } from "/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue?macro=true";
import { default as princess_45cut_45diamonds7eeGYHoXWKMeta } from "/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue?macro=true";
import { default as radiant_45cut_45diamondsASR0NsemcqMeta } from "/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue?macro=true";
import { default as round_45diamondsHMSruvXralMeta } from "/src/pages/education/diamond-education/shapes/round-diamonds.vue?macro=true";
import { default as trillion_45cut_45diamondsoVzGPJdptLMeta } from "/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue?macro=true";
import { default as testimonialsOP6UGSIAzNMeta } from "/src/pages/education/diamond-education/testimonials.vue?macro=true";
import { default as indexwXj5m7cZh6Meta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue?macro=true";
import { default as introductionioT4hAg28aMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue?macro=true";
import { default as mixed_45cutKWTO0JrL7DMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue?macro=true";
import { default as modified_45brilliant_45cut4W7QXTA9YyMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue?macro=true";
import { default as old_45mine_45old_45european_45cutMoBfHQBlGhMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue?macro=true";
import { default as round_45brilliant_45cutyrMYHzCbVVMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue?macro=true";
import { default as step_45cutqoz9sE34rXMeta } from "/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue?macro=true";
import { default as diamond_45educationMW6QW1Y00NMeta } from "/src/pages/education/diamond-education.vue?macro=true";
import { default as enter_45our_45prize_45draw0PpcHxmR95Meta } from "/src/pages/enter-our-prize-draw.vue?macro=true";
import { default as failedbrcbjYHVDlMeta } from "/src/pages/failed.vue?macro=true";
import { default as finance_45informationRY9DSBrTx3Meta } from "/src/pages/finance-information.vue?macro=true";
import { default as forgot_45passwordhkQDFGWAYaMeta } from "/src/pages/forgot-password.vue?macro=true";
import { default as guideoeMlliXxVDMeta } from "/src/pages/gemstones/emerald/guide.vue?macro=true";
import { default as guide80BdYUPhY2Meta } from "/src/pages/gemstones/guide.vue?macro=true";
import { default as guideESek2eBSWXMeta } from "/src/pages/gemstones/ruby/guide.vue?macro=true";
import { default as guideas5mkEiMedMeta } from "/src/pages/gemstones/sapphire/guide.vue?macro=true";
import { default as here_45comes_45the_45supriseGsU0MlTvfeMeta } from "/src/pages/here-comes-the-suprise.vue?macro=true";
import { default as impressum0XojDA8GKUMeta } from "/src/pages/impressum.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as lab_45grown_45diamondsSlRXO0kcG2Meta } from "/src/pages/lab-grown-diamonds.vue?macro=true";
import { default as loginTLBFe6DICLMeta } from "/src/pages/login.vue?macro=true";
import { default as logoutJAIe3EGR5DMeta } from "/src/pages/logout.vue?macro=true";
import { default as loose_45gemstoneshUlS3UyeQqMeta } from "/src/pages/loose-gemstones.vue?macro=true";
import { default as offersBesHVGJGRAMeta } from "/src/pages/offers.vue?macro=true";
import { default as our_45ethicsusrpBUV75EMeta } from "/src/pages/our-ethics.vue?macro=true";
import { default as our_45promisesjpQkpMkdr3Meta } from "/src/pages/our-promises.vue?macro=true";
import { default as page_45not_45found0jy11wTsNJMeta } from "/src/pages/page-not-found.vue?macro=true";
import { default as cardgtQ5zIa6zgMeta } from "/src/pages/payments-return/card.vue?macro=true";
import { default as splititAlG7Upd17mMeta } from "/src/pages/payments-return/splitit.vue?macro=true";
import { default as v12Zs15TfxEjnMeta } from "/src/pages/payments-return/v12.vue?macro=true";
import { default as paypal_45returnt7dgfnL2lhMeta } from "/src/pages/paypal-return.vue?macro=true";
import { default as guide34XRnI4JRSMeta } from "/src/pages/pearls/guide.vue?macro=true";
import { default as privacy_4577diamondsgsAtERu5cXMeta } from "/src/pages/privacy-77diamonds.vue?macro=true";
import { default as register9mc1FDQpDSMeta } from "/src/pages/register.vue?macro=true";
import { default as reset_45passwordwGIRYTe8GpMeta } from "/src/pages/reset-password.vue?macro=true";
import { default as cupids_45Ideali5m78ucfVsMeta } from "/src/pages/seo-pages/cupids-Ideal.vue?macro=true";
import { default as greenland_45rubiesc3Qqr92tB3Meta } from "/src/pages/seo-pages/greenland-rubies.vue?macro=true";
import { default as shapeOKFO3FtHaAMeta } from "/src/pages/seo-pages/shape.vue?macro=true";
import { default as shop_45bagTYRQ3TpGKfMeta } from "/src/pages/shop-bag.vue?macro=true";
import { default as showroomAQgyL4X8EGMeta } from "/src/pages/showroom.vue?macro=true";
import { default as showroomsogvA3KQX1HMeta } from "/src/pages/showrooms.vue?macro=true";
import { default as successrwNTsVp6BfMeta } from "/src/pages/success.vue?macro=true";
import { default as taxdutyBR7X1rnfxPMeta } from "/src/pages/taxduty.vue?macro=true";
export default [
  {
    name: "shop___ar",
    path: "/ar/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___bg",
    path: "/bg/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___cs",
    path: "/cs/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___dk",
    path: "/dk/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___nl",
    path: "/nl/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___en",
    path: "/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___fi",
    path: "/fi/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___fr",
    path: "/fr/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___de",
    path: "/de/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___it",
    path: "/it/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___cn",
    path: "/cn/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___no",
    path: "/no/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___pl",
    path: "/pl/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___pt",
    path: "/pt/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___ro",
    path: "/ro/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___ru",
    path: "/ru/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___es",
    path: "/es/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___se",
    path: "/se/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "shop___hu",
    path: "/hu/:shop(.*)*",
    meta: _91_46_46_46shop_93zjKNText9jMeta || {},
    component: () => import("/src/pages/[...shop].vue")
  },
  {
    name: "758_account-addresses___ar",
    path: "/ar/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"ar"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___bg",
    path: "/bg/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"bg"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___cs",
    path: "/cs/account/adresy",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"cs"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___dk",
    path: "/dk/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"dk"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___nl",
    path: "/nl/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"nl"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___en",
    path: "/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"en"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___fi",
    path: "/fi/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"fi"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___fr",
    path: "/fr/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"fr"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___de",
    path: "/de/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"de"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___it",
    path: "/it/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"it"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___cn",
    path: "/cn/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"cn"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___no",
    path: "/no/konto/adresser",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"no"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___pl",
    path: "/pl/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"pl"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___pt",
    path: "/pt/conta/moradas",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"pt"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___ro",
    path: "/ro/conturi/adrese",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"ro"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___ru",
    path: "/ru/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"ru"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___es",
    path: "/es/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"es"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___se",
    path: "/se/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"se"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "758_account-addresses___hu",
    path: "/hu/account/addresses",
    meta: {"pageId":758,"aspRoute":"/NewApp/Default.aspx?app=addresses","locale":"hu"},
    component: () => import("/src/pages/account/addresses.vue")
  },
  {
    name: "756_account-orders___ar",
    path: "/ar/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"ar"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___ar",
    path: "/ar/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"ar"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___bg",
    path: "/bg/smetka/por-chki",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"bg"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___bg",
    path: "/bg/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"bg"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___cs",
    path: "/cs/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"cs"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___cs",
    path: "/cs/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"cs"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___dk",
    path: "/dk/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"dk"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___dk",
    path: "/dk/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"dk"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___nl",
    path: "/nl/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"nl"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___nl",
    path: "/nl/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"nl"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___en",
    path: "/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"en"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___en",
    path: "/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"en"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___fi",
    path: "/fi/tili/tilaukset",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"fi"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___fi",
    path: "/fi/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"fi"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___fr",
    path: "/fr/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"fr"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___fr",
    path: "/fr/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"fr"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___de",
    path: "/de/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"de"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___de",
    path: "/de/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"de"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___it",
    path: "/it/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"it"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___it",
    path: "/it/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"it"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___cn",
    path: "/cn/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"cn"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___cn",
    path: "/cn/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"cn"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___no",
    path: "/no/konto/bestillinger",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"no"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___no",
    path: "/no/konto/bestilling",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"no"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___pl",
    path: "/pl/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"pl"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___pl",
    path: "/pl/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"pl"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___pt",
    path: "/pt/conta/encomendas",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"pt"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___pt",
    path: "/pt/conta/encomenda",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"pt"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___ro",
    path: "/ro/conturi/ordine",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"ro"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___ro",
    path: "/ro/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"ro"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___ru",
    path: "/ru/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"ru"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___ru",
    path: "/ru/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"ru"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___es",
    path: "/es/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"es"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___es",
    path: "/es/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"es"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___se",
    path: "/se/account/orders",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"se"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___se",
    path: "/se/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"se"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "756_account-orders___hu",
    path: "/hu/account/orderok",
    meta: {"pageId":756,"aspRoute":"/NewApp/Default.aspx?app=orders","locale":"hu"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "757_account-orders___hu",
    path: "/hu/account/order",
    meta: {"pageId":757,"aspRoute":"/NewApp/Default.aspx?app=order","locale":"hu"},
    component: () => import("/src/pages/account/orders.vue")
  },
  {
    name: "759_account-privacy___ar",
    path: "/ar/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/ar/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___bg",
    path: "/bg/kaunt/-poveritelnost",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/bg/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___cs",
    path: "/cs/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/cs/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___dk",
    path: "/dk/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/dk/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___nl",
    path: "/nl/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/nl/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___en",
    path: "/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___fi",
    path: "/fi/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/fi/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___fr",
    path: "/fr/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/fr/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___de",
    path: "/de/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/de/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___it",
    path: "/it/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/it/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___cn",
    path: "/cn/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/cn/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___no",
    path: "/no/konto/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/no/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___pl",
    path: "/pl/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/pl/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___pt",
    path: "/pt/conta/privacidade",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/pt/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___ro",
    path: "/ro/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/ro/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___ru",
    path: "/ru/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/ru/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___es",
    path: "/es/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/es/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___se",
    path: "/se/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/se/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "759_account-privacy___hu",
    path: "/hu/account/privacy",
    meta: { ...(privacyaTLU6InpJAMeta || {}), ...{"pageId":759,"aspRoute":"/NewApp/Default.aspx?app=privacy","locale":"hu"} },
    alias: ["/hu/NewApp/Default.aspx?app=privacy"],
    component: () => import("/src/pages/account/privacy.vue")
  },
  {
    name: "755_account-profile___ar",
    path: "/ar/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"ar"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___bg",
    path: "/bg/akaunt/profil",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"bg"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___cs",
    path: "/cs/ucet/profil",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"cs"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___dk",
    path: "/dk/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"dk"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___nl",
    path: "/nl/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"nl"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___en",
    path: "/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"en"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___fi",
    path: "/fi/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"fi"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___fr",
    path: "/fr/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"fr"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___de",
    path: "/de/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"de"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___it",
    path: "/it/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"it"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___cn",
    path: "/cn/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"cn"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___no",
    path: "/no/konto/profil",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"no"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___pl",
    path: "/pl/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"pl"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___pt",
    path: "/pt/conta/perfil",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"pt"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___ro",
    path: "/ro/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"ro"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___ru",
    path: "/ru/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"ru"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___es",
    path: "/es/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"es"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___se",
    path: "/se/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"se"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "755_account-profile___hu",
    path: "/hu/account/profile",
    meta: {"pageId":755,"aspRoute":"/NewApp/Default.aspx?app=profile","locale":"hu"},
    component: () => import("/src/pages/account/profile.vue")
  },
  {
    name: "32_bespoke-jewellery___ar",
    path: "/ar/hasb_attalb.html",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"ar"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___bg",
    path: "/bg/bizhuta-po-por-chka",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"bg"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___cs",
    path: "/cs/sperky-na-miru",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"cs"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___dk",
    path: "/dk/skraeddersyede-smykker",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"dk"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___nl",
    path: "/nl/maatwerk-sieraden",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"nl"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___en",
    path: "/bespoke-jewellery",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"en"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___fi",
    path: "/fi/erikoisvalmisteiset-korut",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"fi"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___fr",
    path: "/fr/bijoux-sur-mesure",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"fr"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___de",
    path: "/de/individueller-schmuck",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"de"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___it",
    path: "/it/bespoke.html",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"it"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___cn",
    path: "/cn/te-zhi.html",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"cn"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___no",
    path: "/no/-skreddersydde-smykker",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"no"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___pl",
    path: "/pl/bizuteria-na-zamowienie",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"pl"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___pt",
    path: "/pt/bespoke-jewellery",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"pt"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___ro",
    path: "/ro/bijuterii-la-comanda",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"ro"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___ru",
    path: "/rukonsul-tacija.html",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"ru"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___es",
    path: "/es/a-medida",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"es"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___se",
    path: "/se/skraddarsydda-smycken",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"se"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "32_bespoke-jewellery___hu",
    path: "/hu/megrendelt-ekszerek",
    meta: {"pageId":32,"aspRoute":"/NewApp/Default.aspx?app=bespoke","locale":"hu"},
    component: () => import("/src/pages/bespoke-jewellery.vue")
  },
  {
    name: "bespoke-standout-pieces___ar",
    path: "/ar/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___bg",
    path: "/bg/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___cs",
    path: "/cs/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___dk",
    path: "/dk/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___nl",
    path: "/nl/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___en",
    path: "/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___fi",
    path: "/fi/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___fr",
    path: "/fr/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___de",
    path: "/de/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___it",
    path: "/it/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___cn",
    path: "/cn/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___no",
    path: "/no/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___pl",
    path: "/pl/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___pt",
    path: "/pt/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___ro",
    path: "/ro/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___ru",
    path: "/ru/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___es",
    path: "/es/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___se",
    path: "/se/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "bespoke-standout-pieces___hu",
    path: "/hu/bespoke-standout-pieces",
    component: () => import("/src/pages/bespoke-standout-pieces.vue")
  },
  {
    name: "checkout___ar",
    path: "/ar/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___bg",
    path: "/bg/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___cs",
    path: "/cs/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___dk",
    path: "/dk/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___nl",
    path: "/nl/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___fi",
    path: "/fi/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___de",
    path: "/de/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___it",
    path: "/it/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___cn",
    path: "/cn/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___no",
    path: "/no/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___pt",
    path: "/pt/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___ro",
    path: "/ro/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___ru",
    path: "/ru/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___es",
    path: "/es/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___se",
    path: "/se/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "checkout___hu",
    path: "/hu/checkout",
    component: () => import("/src/pages/checkout/index.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___ar",
    path: "/ar/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___bg",
    path: "/bg/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___cs",
    path: "/cs/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___dk",
    path: "/dk/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___nl",
    path: "/nl/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___en",
    path: "/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___fi",
    path: "/fi/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___fr",
    path: "/fr/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___de",
    path: "/de/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___it",
    path: "/it/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___cn",
    path: "/cn/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___no",
    path: "/no/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___pl",
    path: "/pl/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___pt",
    path: "/pt/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___ro",
    path: "/ro/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___ru",
    path: "/ru/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___es",
    path: "/es/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___se",
    path: "/se/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour-cushion-cut-diamonds___hu",
    path: "/hu/contour/cushion-cut-diamonds",
    component: () => import("/src/pages/contour/cushion-cut-diamonds.vue")
  },
  {
    name: "contour___ar",
    path: "/ar/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___bg",
    path: "/bg/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___cs",
    path: "/cs/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___dk",
    path: "/dk/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___nl",
    path: "/nl/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___en",
    path: "/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___fi",
    path: "/fi/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___fr",
    path: "/fr/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___de",
    path: "/de/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___it",
    path: "/it/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___cn",
    path: "/cn/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___no",
    path: "/no/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___pl",
    path: "/pl/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___pt",
    path: "/pt/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___ro",
    path: "/ro/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___ru",
    path: "/ru/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___es",
    path: "/es/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___se",
    path: "/se/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "contour___hu",
    path: "/hu/contour",
    component: () => import("/src/pages/contour/index.vue")
  },
  {
    name: "customer-care___ar",
    path: "/ar/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___ar",
    path: "/ar/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"ar"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___ar",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___ar",
    path: "/ar/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"ar"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___ar",
    path: "/ar/alasala-alshaah.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"ar"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___ar",
    path: "/ar/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"ar"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___ar",
    path: "/ar/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"ar"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___bg",
    path: "/bg/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___bg",
    path: "/bg/kontakt",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"bg"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___bg",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___bg",
    path: "/bg/dostavka-i-vr-shchane",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"bg"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___bg",
    path: "/bg/faq",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"bg"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___bg",
    path: "/bg/finansov-kalkulator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"bg"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___bg",
    path: "/bg/usloviya-na-usloviyata",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"bg"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___cs",
    path: "/cs/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___cs",
    path: "/cs/kontakt",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"cs"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___cs",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___cs",
    path: "/cs/dodani-a-vraceni-zbozi",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"cs"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___cs",
    path: "/cs/faq",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"cs"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___cs",
    path: "/cs/financni-kalkulacka",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"cs"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___cs",
    path: "/cs/podminky",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"cs"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___dk",
    path: "/dk/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___dk",
    path: "/dk/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"dk"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___dk",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___dk",
    path: "/dk/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"dk"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___dk",
    path: "/dk/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"dk"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___dk",
    path: "/dk/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"dk"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___dk",
    path: "/dk/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"dk"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___nl",
    path: "/nl/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___nl",
    path: "/nl/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"nl"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___nl",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___nl",
    path: "/nl/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"nl"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___nl",
    path: "/nl/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"nl"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___nl",
    path: "/nl/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"nl"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___nl",
    path: "/nl/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"nl"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___en",
    path: "/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___en",
    path: "/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"en"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___en",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___en",
    path: "/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"en"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___en",
    path: "/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"en"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___en",
    path: "/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"en"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___en",
    path: "/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"en"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___fi",
    path: "/fi/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___fi",
    path: "/fi/yhteystiedot",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"fi"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___fi",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___fi",
    path: "/fi/toimitus-ja-palautus",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"fi"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___fi",
    path: "/fi/faq",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"fi"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___fi",
    path: "/fi/rahoituslaskuri",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"fi"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___fi",
    path: "/fi/ehtojen-ehdot",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"fi"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___fr",
    path: "/fr/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___fr",
    path: "/fr/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"fr"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___fr",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___fr",
    path: "/fr/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"fr"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___fr",
    path: "/fr/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"fr"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___fr",
    path: "/fr/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"fr"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___fr",
    path: "/fr/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"fr"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___de",
    path: "/de/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___de",
    path: "/de/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"de"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___de",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___de",
    path: "/de/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"de"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___de",
    path: "/de/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"de"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___de",
    path: "/de/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"de"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___de",
    path: "/de/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"de"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___it",
    path: "/it/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___it",
    path: "/it/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"it"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___it",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___it",
    path: "/it/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"it"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___it",
    path: "/it/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"it"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___it",
    path: "/it/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"it"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___it",
    path: "/it/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"it"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___cn",
    path: "/cn/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___cn",
    path: "/cn/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"cn"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___cn",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___cn",
    path: "/cn/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"cn"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___cn",
    path: "/cn/chang-jian-wen-ti.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"cn"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___cn",
    path: "/cn/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"cn"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___cn",
    path: "/cn/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"cn"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___no",
    path: "/no/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___no",
    path: "/no/kontakt",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"no"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___no",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___no",
    path: "/no/levering-og-returnering",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"no"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___no",
    path: "/no/spoersmal",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"no"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___no",
    path: "/no/finanskalkulator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"no"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___no",
    path: "/no/vilkar-og-betingelser",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"no"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___pl",
    path: "/pl/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___pl",
    path: "/pl/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"pl"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___pl",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___pl",
    path: "/pl/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"pl"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___pl",
    path: "/pl/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"pl"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___pl",
    path: "/pl/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"pl"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___pl",
    path: "/pl/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"pl"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___pt",
    path: "/pt/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___pt",
    path: "/pt/contactos",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"pt"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___pt",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___pt",
    path: "/pt/entraga-e-devolucoes",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"pt"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___pt",
    path: "/pt/faq.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"pt"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___pt",
    path: "/pt/calculadora-financiamento",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"pt"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___pt",
    path: "/pt/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"pt"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___ro",
    path: "/ro/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___ro",
    path: "/ro/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"ro"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___ro",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___ro",
    path: "/ro/livrare-si-retururi",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"ro"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___ro",
    path: "/ro/faq",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"ro"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___ro",
    path: "/ro/calculator-financiar",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"ro"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___ro",
    path: "/ro/conditii-de-utilizare",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"ro"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___ru",
    path: "/ru/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___ru",
    path: "/ru/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"ru"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___ru",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___ru",
    path: "/ru/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"ru"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___ru",
    path: "/ru/chasto-zadavaemye-voprosy.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"ru"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___ru",
    path: "/ru/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"ru"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___ru",
    path: "/ru/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"ru"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___es",
    path: "/es/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___es",
    path: "/es/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"es"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___es",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___es",
    path: "/es/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"es"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___es",
    path: "/es/faq",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"es"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___es",
    path: "/es/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"es"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___es",
    path: "/es/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"es"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___se",
    path: "/se/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___se",
    path: "/se/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"se"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___se",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___se",
    path: "/se/delivery-and-returns",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"se"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___se",
    path: "/se/fakta.html",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"se"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___se",
    path: "/se/finance-calculator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"se"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___se",
    path: "/se/terms-conditions",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"se"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "customer-care___hu",
    path: "/hu/customer-care",
    component: () => import("/src/pages/customer-care.vue"),
    children: [
  {
    name: "740_customer-care-contact___hu",
    path: "/hu/contact",
    meta: {"pageId":740,"aspRoute":"/NewApp/Default.aspx?app=contact","locale":"hu"},
    component: () => import("/src/pages/customer-care/contact.vue")
  },
  {
    name: "customer-care-customer-care___hu",
    path: "customer-care",
    component: () => import("/src/pages/customer-care/customer-care.vue")
  },
  {
    name: "766_customer-care-delivery-and-returns___hu",
    path: "/hu/szallitas-es-visszaru",
    meta: {"pageId":766,"aspRoute":"/NewApp/Default.aspx?app=delivery","locale":"hu"},
    component: () => import("/src/pages/customer-care/delivery-and-returns.vue")
  },
  {
    name: "60_customer-care-faq___hu",
    path: "/hu/faq",
    meta: {"pageId":60,"aspRoute":"/NewApp/Default.aspx?app=faqs","locale":"hu"},
    component: () => import("/src/pages/customer-care/faq.vue")
  },
  {
    name: "765_customer-care-finance-calculator___hu",
    path: "/hu/penzugyi-kalkulator",
    meta: {"pageId":765,"aspRoute":"/NewApp/Default.aspx?app=finance","locale":"hu"},
    component: () => import("/src/pages/customer-care/finance-calculator.vue")
  },
  {
    name: "739_customer-care-terms-conditions___hu",
    path: "/hu/terms-feltetelek",
    meta: {"pageId":739,"aspRoute":"/NewApp/Default.aspx?app=terms","locale":"hu"},
    component: () => import("/src/pages/customer-care/terms-conditions.vue")
  }
]
  },
  {
    name: "175_diamond-jewellery___ar",
    path: "/ar/mojouhraat-maasyah.html",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"ar"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___bg",
    path: "/bg/-bizhuta-s-diamanti",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"bg"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___cs",
    path: "/cs/diamantove-sperky",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"cs"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___dk",
    path: "/dk/diamantsmykker",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"dk"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___nl",
    path: "/nl/diamanten-sieraden",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"nl"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___en",
    path: "/diamond-jewellery",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"en"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___fi",
    path: "/fi/diamondikoru",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"fi"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___fr",
    path: "/fr/bijoux-en-diamant",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"fr"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___de",
    path: "/de/diamantschmuck",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"de"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___it",
    path: "/it/gioielli-diamanti",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"it"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___cn",
    path: "/cn/zuan-shi-shou-shi.html",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"cn"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___no",
    path: "/no/diamantsmykker",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"no"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___pl",
    path: "/pl/bizuteria-z-diamentami",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"pl"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___pt",
    path: "/pt/joias-de-diamantes",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"pt"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___ro",
    path: "/ro/bijuterii-de-diamant",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"ro"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___ru",
    path: "/ru/brilliantovye-izdelija",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"ru"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___es",
    path: "/es/joyas-de-diamantes",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"es"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___se",
    path: "/se/diamantsmycken",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"se"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "175_diamond-jewellery___hu",
    path: "/hu/gyemant-ekszerek",
    meta: {"pageId":175,"aspRoute":"/DiamondJewellery.aspx","locale":"hu"},
    component: () => import("/src/pages/diamond-jewellery.vue")
  },
  {
    name: "177_diamonds___ar",
    path: "/ar/almaasaat_online.html",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"ar"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___bg",
    path: "/bg/diamanti",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"bg"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___cs",
    path: "/cs/diamanty",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"cs"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___dk",
    path: "/dk/diamanter",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"dk"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___nl",
    path: "/nl/diamanten",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"nl"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___en",
    path: "/diamonds",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"en"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___fi",
    path: "/fi/timantit",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"fi"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___fr",
    path: "/fr/diamants",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"fr"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___de",
    path: "/de/diamanten",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"de"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___it",
    path: "/it/diamanti_online.html",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"it"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___cn",
    path: "/cn/zuan-shi-zai-xian.html",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"cn"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___no",
    path: "/no/diamanter",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"no"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___pl",
    path: "/pl/diamenty",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"pl"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___pt",
    path: "/pt/diamantes",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"pt"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___ro",
    path: "/ro/diamante",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"ro"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___ru",
    path: "/ru/brillianty_onlajn.html",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"ru"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___es",
    path: "/es/diamantes_en_linea",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"es"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___se",
    path: "/se/diamanter",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"se"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: "177_diamonds___hu",
    path: "/hu/diamonds",
    meta: {"pageId":177,"aspRoute":"/Diamonds.aspx","locale":"hu"},
    component: () => import("/src/pages/diamonds.vue")
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/ar/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___ar",
    path: "/ar/matbou-at-77daaymoundz",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___ar",
    path: "/ar/hawl-77daaymoundz.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___ar",
    path: "/ar/tashreeh-almaasa.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___ar",
    path: "/ar/tafaaseel-aljawaaiz.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___ar",
    path: "/ar/jawaaiz",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___ar",
    path: "/ar/almaas-alkanadee.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___ar",
    path: "/ar/wazaaif.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___ar",
    path: "/ar/almaasaat-mohsnt-alnaqaa-.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___ar",
    path: "/ar/moqaarnt-shahaadaat-almaas.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___ar",
    path: "/ar/alttaklfa.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___ar",
    path: "/ar/khwaatm-alkhotbah/daleel-sharaa-almaas",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___ar",
    path: "/ar/wazn-almaasa-belqeeraat.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___ar",
    path: "/ar/shahaadaat-almaas.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___ar",
    path: "/ar/nasihat-alkhobraa-nqaa-almas.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___ar",
    path: "/ar/taqyeem-naqaa-almaasa.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___ar",
    path: "/ar/naqaa-almaasa.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___ar",
    path: "/ar/lawn-almaasa.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___ar",
    path: "/ar/taqyeem-qat--almaasa.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___ar",
    path: "/ar/-amalyt-_qata-almaas.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___ar",
    path: "/ar/qat--almaasa.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___ar",
    path: "/ar/almaasaat-mtouhjah.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___ar",
    path: "/ar/mokhtbraat-tsneef-almaas.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___ar",
    path: "/ar/shawaa-ib-almaas.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___ar",
    path: "/ar/ashkaal-almaas.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___ar",
    path: "/ar/khwaatem-alkotbah/dleel-shraa-khotbah",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___ar",
    path: "/ar/mas-akhlaqi.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___ar",
    path: "/ar/almasat-molonah-fakhrah.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___ar",
    path: "/ar/ashm-qloub.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___ar",
    path: "/ar/kif-yoqta-almaas.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___ar",
    path: "/ar/kif-yoshkl-almaas.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___ar",
    path: "/ar/tawaia-belmaas.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___ar",
    path: "/ar/qisatona",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___ar",
    path: "/ar/khwaatm-alkhotba/jadoul_-maqaas-alkhaatm",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___ar",
    path: "/ar/almaasaat-qata-morba.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___ar",
    path: "/ar/almaasaat-qat-wasaada.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___ar",
    path: "/ar/almaasaat-qata-zomorodi.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___ar",
    path: "/ar/almaasaat-qta-qlb.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___ar",
    path: "/ar/almaasaat-qta-maarkeez.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___ar",
    path: "/ar/-almaasaat-qata-baydaawee.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___ar",
    path: "/ar/-almaasaat-qata-komthree.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___ar",
    path: "/ar/-almaasaat-qata-brinses.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___ar",
    path: "/ar/-almaasaat-qata-mostateel.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___ar",
    path: "/ar/-almaasaat-mostadeera.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___ar",
    path: "/ar/-almaasaat-qata-treelyoun.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___ar",
    path: "/ar/tazkyaat.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___ar",
    path: "/ar/ashkaal-qata-almaas.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___ar",
    path: "/ar/moqdmah.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___ar",
    path: "/ar/qata-mokhtlt.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___ar",
    path: "/ar/qata-albareelyaant-alma-dl.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___ar",
    path: "/ar/qata-awroubee-qadeem-manjmee-qadeem.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___ar",
    path: "/ar/qata-mostadeer-motaalq.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___ar",
    path: "/ar/qata-modrj.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"ar"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/bg/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___bg",
    path: "/bg/77diamonds-press",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___bg",
    path: "/bg/okolo-77-diamanta",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___bg",
    path: "/bg/anatomiya-na-diamant",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___bg",
    path: "/bg/podrobnosti-za-nagradite",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___bg",
    path: "/bg/nagradi",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___bg",
    path: "/bg/kanadski-diamanti",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___bg",
    path: "/bg/kariera",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___bg",
    path: "/bg/podobreni-diamanti",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___bg",
    path: "/bg/sravnyavane-na-diamanteni-sertifikati",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___bg",
    path: "/bg/cost",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___bg",
    path: "/bg/angazhirashchi-pr-steni/r-kovodstvo-za-pokupka-na-diamanti",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___bg",
    path: "/bg/karatovo-teglo-na-diamanta",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___bg",
    path: "/bg/diamanteni-sertifikati",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___bg",
    path: "/bg/ekspertni-s-veti-za-chistotata-na-diamantite",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___bg",
    path: "/bg/klasifikaciya-na-chistotata-na-diamanta",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___bg",
    path: "/bg/yasnota-na-diamanta",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___bg",
    path: "/bg/cvyat-na-diamant",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___bg",
    path: "/bg/klasifikaciya-s-diamanteno-shlifovane",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___bg",
    path: "/bg/proces-na-diamanteno-ryazane",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___bg",
    path: "/bg/diamanteno-shlifovane",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___bg",
    path: "/bg/diamantena-fluorescenciya",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___bg",
    path: "/bg/laboratorii-za-klasifikaciya-na-diamanti",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___bg",
    path: "/bg/diamanteni-vklyuchvaniya",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___bg",
    path: "/bg/diamanteni-formi",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___bg",
    path: "/bg/-pr-steni-za-godezh/-r-kovodstvo-za-pokupka-na-pr-steni-za-godezh",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___bg",
    path: "/bg/etichni-diamanti",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___bg",
    path: "/bg/cvetni-diamanti",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___bg",
    path: "/bg/strelki-za-s-rce",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___bg",
    path: "/bg/kak-se-shlifovat-diamantite",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___bg",
    path: "/bg/kak-se-obrazuvat-diamantite",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___bg",
    path: "/bg/obrazovanie-s-diamanti",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___bg",
    path: "/bg/nashata-istoriya",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___bg",
    path: "/bg/-godezhni-pr-steni/-diagrama-na-razmerite-na-pr-stenite",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___bg",
    path: "/bg/diamanti,-shlifovani-po-metoda-na-asher",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___bg",
    path: "/bg/diamanti-s-shlifovana-v-zglavnica.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___bg",
    path: "/bg/diamanti-s-diamanteno-shlifovane",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___bg",
    path: "/bg/diamanti-s-shlifovano-s-rce",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___bg",
    path: "/bg/diamanti-s-brilyantna-shlifovka",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___bg",
    path: "/bg/ovalno-shlifovani-diamanti",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___bg",
    path: "/bg/diamanti-s-forma-na-krusha",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___bg",
    path: "/bg/diamanti-s-shlifovka-princesa",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___bg",
    path: "/bg/diamanti-s-radiantsko-shlifovane",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___bg",
    path: "/bg/kr-gli-diamanti",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___bg",
    path: "/bg/trilionno-shlifovani-diamanti",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___bg",
    path: "/bg/prepor-ki",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___bg",
    path: "/bg/vidove-diamanteni-shlifovki",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___bg",
    path: "/bg/v-vedenie",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___bg",
    path: "/bg/smesen-razrez",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___bg",
    path: "/bg/modificiran-brilyanten-razrez",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___bg",
    path: "/bg/old-mine-old-european-cut",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___bg",
    path: "/bg/kr-g-l-brilyant",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___bg",
    path: "/bg/-st-pka-cut",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"bg"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/cs/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___cs",
    path: "/cs/77diamonds-press",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___cs",
    path: "/cs/o-77-diamantech",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___cs",
    path: "/cs/anatomie-diamantu",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___cs",
    path: "/cs/podrobnosti-o-ocenenich",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___cs",
    path: "/cs/odmeny",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___cs",
    path: "/cs/kanadske-diamanty",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___cs",
    path: "/cs/kariera",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___cs",
    path: "/cs/zvysena-pruhlednost-diamantu",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___cs",
    path: "/cs/porovnani-diamantovych-certifikatu",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___cs",
    path: "/cs/naklady",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___cs",
    path: "/cs/snubni-prsteny/pruvodce-nakupem-diamantu",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___cs",
    path: "/cs/karatova-hmotnost-diamantu",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___cs",
    path: "/cs/diamantove-certifikaty",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___cs",
    path: "/cs/odborne-poradenstvi-o-cistote-diamantu",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___cs",
    path: "/cs/trideni-cistoty-diamantu",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___cs",
    path: "/cs/cistota-diamantu",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___cs",
    path: "/cs/diamantova-barva",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___cs",
    path: "/cs/trideni-diamantu",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___cs",
    path: "/cs/proces-brouseni-diamantu",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___cs",
    path: "/cs/diamantovy-brus",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___cs",
    path: "/cs/diamantova-fluorescence",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___cs",
    path: "/cs/laboratore-pro-trideni-diamantu",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___cs",
    path: "/cs/diamantove-inkluze",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___cs",
    path: "/cs/diamantove-tvary",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___cs",
    path: "/cs/pruvodce-nakupem-zasnubnich-prstenu/zasnoubeni",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___cs",
    path: "/cs/eticke-diamanty",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___cs",
    path: "/cs/fantasticke-barevne-diamanty",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___cs",
    path: "/cs/sipky-srdce",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___cs",
    path: "/cs/jak-se-brousi-diamanty",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___cs",
    path: "/cs/jak-vznikaji-diamanty",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___cs",
    path: "/cs/diamantove-vzdelani",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___cs",
    path: "/cs/nas-pribeh",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___cs",
    path: "/cs/snubni-prsteny/tabulka-velikosti-prstenu",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___cs",
    path: "/cs/asscheruv-brus",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___cs",
    path: "/cs/brousene-diamanty-ve-tvaru-polstare.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___cs",
    path: "/cs/emeraldove-brousene-diamanty",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___cs",
    path: "/cs/brousene-diamanty",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___cs",
    path: "/cs/marquise-cut-diamanty",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___cs",
    path: "/cs/ovalny-brus-diamantu",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___cs",
    path: "/cs/-diamanty-ve-tvaru-hrusky",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___cs",
    path: "/cs/princess-cut-diamonds",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___cs",
    path: "/cs/radiantni-diamanty",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___cs",
    path: "/cs/kulate-diamanty",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___cs",
    path: "/cs/trilion-cut-diamonds",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___cs",
    path: "/cs/zkusenosti",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___cs",
    path: "/cs/typy-diamantovych-brusu",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___cs",
    path: "/cs/uvod",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___cs",
    path: "/cs/smiseny-rez",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___cs",
    path: "/cs/modifikovany-briliantovy-rez",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___cs",
    path: "/cs/stary-dul-stary-evropsky-strih",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___cs",
    path: "/cs/kulaty-briliantovy-brus",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___cs",
    path: "/cs/krokovy-rez",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"cs"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/dk/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___dk",
    path: "/dk/77diamonds-presse.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___dk",
    path: "/dk/om-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___dk",
    path: "/dk/en-diamants-anatomi.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___dk",
    path: "/dk/priser-detaljer.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___dk",
    path: "/dk/priser",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___dk",
    path: "/dk/canadiske-diamanter.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___dk",
    path: "/dk/karriere.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___dk",
    path: "/dk/klarhedsfremhaevende-diamanter.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___dk",
    path: "/dk/sammenlign-diamantcertifikater.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___dk",
    path: "/dk/pris.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___dk",
    path: "/dk/forlovelsesringe/kobsguide-til-diamanter",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___dk",
    path: "/dk/diamantens-karatvaegt.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___dk",
    path: "/dk/diamantcertifikater.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___dk",
    path: "/dk/diamantklarhed-ekspertrad.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___dk",
    path: "/dk/diamant-klarhedsgradering.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___dk",
    path: "/dk/diamantklarhed.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___dk",
    path: "/dk/diamant-farve.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___dk",
    path: "/dk/diamant-slibningsgrader.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___dk",
    path: "/dk/diamantslibningsprocessen.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___dk",
    path: "/dk/diamant-slibning.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___dk",
    path: "/dk/diamant-fluorescence.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___dk",
    path: "/dk/diamantgraderingslaboratorie.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___dk",
    path: "/dk/diamant-indeslutninger.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___dk",
    path: "/dk/diamantform.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___dk",
    path: "/dk/forlovelsesringe/kobeguide-til-forlovelsesringe",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___dk",
    path: "/dk/etiske-diamanter.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___dk",
    path: "/dk/fancy-farvede-diamanter.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___dk",
    path: "/dk/hjerte-pile.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___dk",
    path: "/dk/hvordan-bliver-diamanter-slebet.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___dk",
    path: "/dk/hvordan-skabes-diamanter.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___dk",
    path: "/dk/diamant-uddannelse.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___dk",
    path: "/dk/our-story",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___dk",
    path: "/dk/forlovelsesringe/ringstorrelsestabel",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___dk",
    path: "/dk/asscher-slebede-diamanter.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___dk",
    path: "/dk/pude-slebne-diamanter.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___dk",
    path: "/dk/smaragd-slebne-diamanter.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___dk",
    path: "/dk/hjerte-slebne-diamanter.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___dk",
    path: "/dk/marquise-slebne-diamanter.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___dk",
    path: "/dk/oval-slebne-diamanter.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___dk",
    path: "/dk/paereformede-diamanter.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___dk",
    path: "/dk/prinssese-slebne-diamanter.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___dk",
    path: "/dk/radiant-slebne-diamanter.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___dk",
    path: "/dk/runde-diamanter.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___dk",
    path: "/dk/trillion-slebne-diamanter.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___dk",
    path: "/dk/kundereferencer.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___dk",
    path: "/dk/typer-af-diamant-slibninger.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___dk",
    path: "/dk/introduktion.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___dk",
    path: "/dk/mix-slib.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___dk",
    path: "/dk/modificered-brilliantslibning.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___dk",
    path: "/dk/old-mine-slib-old-european-slib.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___dk",
    path: "/dk/rund-brilliantslibning.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___dk",
    path: "/dk/trappe-slibning.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"dk"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/nl/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___nl",
    path: "/nl/77diamonds-media.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___nl",
    path: "/nl/over-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___nl",
    path: "/nl/anatomie-van-een-diamant.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___nl",
    path: "/nl/awards-details.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___nl",
    path: "/nl/awards",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___nl",
    path: "/nl/canadese-diamanten.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___nl",
    path: "/nl/carrires.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___nl",
    path: "/nl/zuiverheid-verbeterde-diamanten.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___nl",
    path: "/nl/vergelijk-diamant-certificaten.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___nl",
    path: "/nl/prijs.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___nl",
    path: "/nl/verlovingsringen/diamanten-koopgids",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___nl",
    path: "/nl/diamant-karaatgewicht.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___nl",
    path: "/nl/diamant-certificaten.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___nl",
    path: "/nl/diamant-zuiverheid-expert-advies.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___nl",
    path: "/nl/diamant-zuiverheid-gradatie.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___nl",
    path: "/nl/diamant-zuiverheid.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___nl",
    path: "/nl/diamant-kleur.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___nl",
    path: "/nl/diamant-slijpkwaliteit-gradatie.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___nl",
    path: "/nl/diamant-slijpen-proces.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___nl",
    path: "/nl/diamant-slijpvorm.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___nl",
    path: "/nl/diamant-fluorescentie.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___nl",
    path: "/nl/diamant-beoordeling-lab.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___nl",
    path: "/nl/diamant-insluitsels.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___nl",
    path: "/nl/diamantvormen.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___nl",
    path: "/nl/verlovingsringen/koopgids-verloving",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___nl",
    path: "/nl/ethische-diamanten.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___nl",
    path: "/nl/fancy-gekleurde-diamanten.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___nl",
    path: "/nl/hearts-arrows.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___nl",
    path: "/nl/hoe-worden-diamanten-geslepen.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___nl",
    path: "/nl/hoe-worden-diamanten-gevormd.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___nl",
    path: "/nl/diamant-educatie.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___nl",
    path: "/nl/ons-verhaal",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___nl",
    path: "/nl/verlovingsringen/ringmaat-tabel",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___nl",
    path: "/nl/asscher-geslepen-diamanten.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___nl",
    path: "/nl/cushion-geslepen-diamanten.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___nl",
    path: "/nl/emerald-geslepen-diamanten.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___nl",
    path: "/nl/hart-geslepen-diamanten.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___nl",
    path: "/nl/marquise-geslepen-diamanten.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___nl",
    path: "/nl/ovaal-geslepen-diamanten.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___nl",
    path: "/nl/peervormige-diamanten.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___nl",
    path: "/nl/princess-geslepen-diamanten.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___nl",
    path: "/nl/radiant-geslepen-diamanten.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___nl",
    path: "/nl/ronde-diamanten.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___nl",
    path: "/nl/triljoen-geslepen-diamanten.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___nl",
    path: "/nl/getuigenissen.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___nl",
    path: "/nl/type-diamant-slijpvormen.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___nl",
    path: "/nl/introductie.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___nl",
    path: "/nl/gemengde-slijpvorm.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___nl",
    path: "/nl/gemodificeerd-briljant-slijpvorm.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___nl",
    path: "/nl/oude-mijn-oud-europese-slijpvorm.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___nl",
    path: "/nl/ronde-briljant-slijpvorm.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___nl",
    path: "/nl/step-slijpvorm.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"nl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___en",
    path: "/77diamonds-press.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___en",
    path: "/about-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___en",
    path: "/anatomy-of-a-diamond.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___en",
    path: "/awards-details.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___en",
    path: "/awards",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___en",
    path: "/canadian-diamonds.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___en",
    path: "/careers.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___en",
    path: "/clarity-enhanced-diamonds.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___en",
    path: "/compare-diamond-certificates.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___en",
    path: "/cost.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___en",
    path: "/engagement-rings/diamond-buying-guide",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___en",
    path: "/diamond-carat-weight.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___en",
    path: "/diamond-certificates.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___en",
    path: "/diamond-clarity-expert-advice.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___en",
    path: "/diamond-clarity-grading.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___en",
    path: "/diamond-clarity.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___en",
    path: "/diamond-colour.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___en",
    path: "/diamond-cut-grading.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___en",
    path: "/diamond-cut-process.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___en",
    path: "/diamond-cut.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___en",
    path: "/diamond-fluorescence.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___en",
    path: "/diamond-grading-labs.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___en",
    path: "/diamond-inclusions.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___en",
    path: "/diamond-shapes.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___en",
    path: "/engagement-rings/engagement-buying-guide",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___en",
    path: "/ethical-diamonds.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___en",
    path: "/fancy-coloured-diamonds.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___en",
    path: "/hearts-arrows.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___en",
    path: "/how-are-diamonds-cut.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___en",
    path: "/how-are-diamonds-formed.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___en",
    path: "/diamond-education.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___en",
    path: "/our-story",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___en",
    path: "/engagement-rings/ring-size-chart",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___en",
    path: "/asscher-cut-diamonds.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___en",
    path: "/cushion-cut-diamonds.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___en",
    path: "/emerald-cut-diamonds.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___en",
    path: "/heart-cut-diamonds.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___en",
    path: "/marquise-cut-diamonds.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___en",
    path: "/oval-cut-diamonds.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___en",
    path: "/pear-shape-diamonds.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___en",
    path: "/princess-cut-diamonds.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___en",
    path: "/radiant-cut-diamonds.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___en",
    path: "/round-diamonds.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___en",
    path: "/trillion-cut-diamonds.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___en",
    path: "/testimonials.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___en",
    path: "/type-of-diamond-cuts.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___en",
    path: "/introduction.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___en",
    path: "/mixed-cut.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___en",
    path: "/modified-brilliant-cut.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___en",
    path: "/old-mine-old-european-cut.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___en",
    path: "/round-brilliant-cut.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___en",
    path: "/step-cut.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"en"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/fi/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___fi",
    path: "/fi/77diamonds-press",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___fi",
    path: "/fi/noin-77-timanttia",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___fi",
    path: "/fi/timantin-anatomia",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___fi",
    path: "/fi/palkintojen-tiedot",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___fi",
    path: "/fi/palkinnot",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___fi",
    path: "/fi/kanadalaiset-timantit",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___fi",
    path: "/fi/urat",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___fi",
    path: "/fi/selkeytta-lisaavat-timantit",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___fi",
    path: "/fi/vertaa-timanttisertifikaatteja-keskenaan",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___fi",
    path: "/fi/kustannus",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___fi",
    path: "/fi/kihlasormukset/diamantin-osto-opas",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___fi",
    path: "/fi/diamantin-karaattipaino",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___fi",
    path: "/fi/diamonditodistukset",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___fi",
    path: "/fi/diamantin-kirkkauden-asiantuntijaneuvonta",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___fi",
    path: "/fi/diamanttien-kirkkauden-luokitus",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___fi",
    path: "/fi/diamondin-kirkkaus",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___fi",
    path: "/fi/diamantin-vari",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___fi",
    path: "/fi/diamanttileikkausluokitus",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___fi",
    path: "/fi/diamanttileikkausprosessi",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___fi",
    path: "/fi/diamanttileikkaus",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___fi",
    path: "/fi/diamanttifluoresenssi",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___fi",
    path: "/fi/diamantti-luokituslaboratoriot",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___fi",
    path: "/fi/diamantti-sulkeumat",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___fi",
    path: "/fi/diamanttien-muodot",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___fi",
    path: "/fi/kihlasormukset/kihlauksen-osto-opas",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___fi",
    path: "/fi/eettiset-timantit",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___fi",
    path: "/fi/fancy-varillisia-timantteja",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___fi",
    path: "/fi/sydamen-nuolet",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___fi",
    path: "/fi/miten-timantit-hiotaan?",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___fi",
    path: "/fi/miten-timantit-muodostuvat?",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___fi",
    path: "/fi/diamondikoulutus",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___fi",
    path: "/fi/tarinamme",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___fi",
    path: "/fi/kihlasormukset/sormusten-kokotaulukko",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___fi",
    path: "/fi/asscher-hiotut-timantit",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___fi",
    path: "/fi/tyynyleikattuja-timantteja.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___fi",
    path: "/fi/emerald-hiontaiset-timantit",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___fi",
    path: "/fi/sydanhiontaiset-timantit",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___fi",
    path: "/fi/marquise-hiontaiset-timantit",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___fi",
    path: "/fi/ovaalihiontaiset-timantit",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___fi",
    path: "/fi/paarynan-muotoiset-timantit",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___fi",
    path: "/fi/prinsessahiontaiset-timantit",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___fi",
    path: "/fi/radianttihiotut-timantit",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___fi",
    path: "/fi/pyoreat-timantit",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___fi",
    path: "/fi/triljoona-hiottua-timanttia",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___fi",
    path: "/fi/esimerkkeja",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___fi",
    path: "/fi/timanttileikkaustyyppi",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___fi",
    path: "/fi/esittelyssa",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___fi",
    path: "/fi/sekoitettu-leikkaus",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___fi",
    path: "/fi/muokattu-loistava-leikkaus",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___fi",
    path: "/fi/vanha-kaivos-vanha-eurooppalainen-leikkaus",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___fi",
    path: "/fi/pyoristetty-briljanttihionta",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___fi",
    path: "/fi/vaihe-leikkaus",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"fi"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/fr/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___fr",
    path: "/fr/77diamonds-presse.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___fr",
    path: "/fr/a-propos-de-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___fr",
    path: "/fr/anatomie-d-un-diamant.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___fr",
    path: "/fr/details-recompenses.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___fr",
    path: "/fr/prix",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___fr",
    path: "/fr/diamants-canadiens.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___fr",
    path: "/fr/carrieres.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___fr",
    path: "/fr/diamants-purete-amelioree.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___fr",
    path: "/fr/comparer-diamant-certificats.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___fr",
    path: "/fr/prix.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___fr",
    path: "/fr/bagues-de-fiancailles/guide-d-achat-de-diamants",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___fr",
    path: "/fr/diamant-carat-poids.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___fr",
    path: "/fr/diamant-certificat.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___fr",
    path: "/fr/conseil-expert-purete-diamant.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___fr",
    path: "/fr/classification-purete-diamant.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___fr",
    path: "/fr/diamant-purete.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___fr",
    path: "/fr/couleur-diamant.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___fr",
    path: "/fr/diamants-taille-classification.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___fr",
    path: "/fr/diamants-processus-taille.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___fr",
    path: "/fr/diamants-taille.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___fr",
    path: "/fr/diamant-fluorescence.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___fr",
    path: "/fr/laboratoires-de-classification-de-diamant.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___fr",
    path: "/fr/diamant-inclusions.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___fr",
    path: "/fr/formes-diamant.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___fr",
    path: "/fr/bagues-de-fiancailles/guide-d-achat-de-bagues-de-fiancailles",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___fr",
    path: "/fr/diamants-ethiques.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___fr",
    path: "/fr/diamants-couleur-fantaisie.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___fr",
    path: "/fr/coeur-fleches.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___fr",
    path: "/fr/comment-sont-tailles-les-diamants.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___fr",
    path: "/fr/comment-sont-formes-les-diamants.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___fr",
    path: "/fr/diamant-education.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___fr",
    path: "/fr/notre-histoire",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___fr",
    path: "/fr/bagues-de-fiancailles/tableau-taille-bague",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___fr",
    path: "/fr/asscher-taille-diamants.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___fr",
    path: "/fr/diamants-taille-coussin.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___fr",
    path: "/fr/diamants-taille-emeraude.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___fr",
    path: "/fr/diamants-taille-coeur.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___fr",
    path: "/fr/diamants-taille-marquise.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___fr",
    path: "/fr/diamants-taille-ovale.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___fr",
    path: "/fr/diamants-taille-poire.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___fr",
    path: "/fr/diamants-taille-princesse.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___fr",
    path: "/fr/diamants-taille-radiant.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___fr",
    path: "/fr/diamants-ronds.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___fr",
    path: "/fr/diamants-taille-triangle.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___fr",
    path: "/fr/temoignages.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___fr",
    path: "/fr/types-de-taille-diamant.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___fr",
    path: "/fr/introduction.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___fr",
    path: "/fr/taille-mixte.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___fr",
    path: "/fr/modifie-brillant-taille.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___fr",
    path: "/fr/ancienne-mine-ancienne-taille-europeenne.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___fr",
    path: "/fr/taille-brillant-rond.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___fr",
    path: "/fr/taille-a-degres.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"fr"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/de/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___de",
    path: "/de/77diamonds-presse.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___de",
    path: "/de/ueber-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___de",
    path: "/de/anatomie-eines-diamanten.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___de",
    path: "/de/auszeichnungen-details.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___de",
    path: "/de/auszeichnungen",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___de",
    path: "/de/kanadische-diamanten.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___de",
    path: "/de/karriere.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___de",
    path: "/de/reinheitsverbesserte-diamanten.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___de",
    path: "/de/diamantzertifikate-vergleichen.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___de",
    path: "/de/kosten.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___de",
    path: "/de/verlobungsring/diamanten-kaufberatung",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___de",
    path: "/de/diamant-karat-gewicht.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___de",
    path: "/de/diamantzertifikate.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___de",
    path: "/de/diamantreinheit-expertenrat.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___de",
    path: "/de/diamanten-reinheitsgrade.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___de",
    path: "/de/diamantreinheit.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___de",
    path: "/de/diamanten-farbgraduierung.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___de",
    path: "/de/diamanten-schliffbewertung.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___de",
    path: "/de/diamanten-schliffverfahren.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___de",
    path: "/de/diamant-schliff.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___de",
    path: "/de/fluoreszierende-diamanten.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___de",
    path: "/de/gemmologische-labore.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___de",
    path: "/de/diamanten-einschluesse.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___de",
    path: "/de/diamant-formen.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___de",
    path: "/de/verlobungsring/kaufberatung",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___de",
    path: "/de/blutdiamanten.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___de",
    path: "/de/farbige-diamanten.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___de",
    path: "/de/herzen-pfeile.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___de",
    path: "/de/so-werden-diamanten-geschliffen.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___de",
    path: "/de/so-entstehen-diamanten.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___de",
    path: "/de/diamanten-wissen.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___de",
    path: "/de/unsere-geschichte",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___de",
    path: "/de/verlobungsring/ringgrobe",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___de",
    path: "/de/diamanten-mit-asscher-schliff.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___de",
    path: "/de/diamanten-mit-cushion-schliff.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___de",
    path: "/de/diamanten-mit-smaragd-schliff.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___de",
    path: "/de/diamanten-mit-herzschliff.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___de",
    path: "/de/diamanten-mit-marquise-schliff.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___de",
    path: "/de/diamanten-mit-ovalschliff.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___de",
    path: "/de/diamanten-mit-tropfenschliff.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___de",
    path: "/de/diamanten-prinzess-schliff.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___de",
    path: "/de/diamanten-mit-radiant-schliff.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___de",
    path: "/de/runde-diamanten.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___de",
    path: "/de/diamanten-mit-trillion-schliff.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___de",
    path: "/de/referenzen.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___de",
    path: "/de/diamantformen.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___de",
    path: "/de/einfuehrung.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___de",
    path: "/de/gemischter-schliff.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___de",
    path: "/de/modifizierter-brillantschliff.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___de",
    path: "/de/alte-mine-alter-europaischer-schnitt.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___de",
    path: "/de/brilliantschliff-rund.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___de",
    path: "/de/treppenschliff.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"de"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/it/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___it",
    path: "/it/77diamonds-stampa.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___it",
    path: "/it/riguardo-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___it",
    path: "/it/anatomia-di-un-diamante.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___it",
    path: "/it/premi-dettagli.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___it",
    path: "/it/premi",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___it",
    path: "/it/diamanti-canadesi.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___it",
    path: "/it/carriere.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___it",
    path: "/it/purezza-migliorata-diamanti.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___it",
    path: "/it/confronta-certificati-diamanti.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___it",
    path: "/it/costo.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___it",
    path: "/it/anelli-fidanzamento/guida-acquisto-diamante",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___it",
    path: "/it/peso-diamanti-carati.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___it",
    path: "/it/certificati-diamanti.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___it",
    path: "/it/consiglio-esperto-purezza-diamante.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___it",
    path: "/it/valutazione-purezza-diamante.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___it",
    path: "/it/purezza-diamante.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___it",
    path: "/it/colore-diamante.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___it",
    path: "/it/valutazione-taglio-diamante.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___it",
    path: "/it/processo-taglio-diamanti.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___it",
    path: "/it/taglio-diamante.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___it",
    path: "/it/fluorescenza-diamanti.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___it",
    path: "/it/categorie-valutazione-diamanti.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___it",
    path: "/it/aggiungere-diamanti.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___it",
    path: "/it/forme-diamante.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___it",
    path: "/it/anelli-fidanzamento/guida-acquisto-fidanzamento",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___it",
    path: "/it/diamanti-etici.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___it",
    path: "/it/diamanti-fancy-colorati.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___it",
    path: "/it/frecce-cuore.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___it",
    path: "/it/come-vengono-tagliati-diamanti.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___it",
    path: "/it/come-si-formano-diamanti.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___it",
    path: "/it/educazione-diamante.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___it",
    path: "/it/la-nostra-storia",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___it",
    path: "/it/anelli-fidanzamento/tabella-misura-anello",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___it",
    path: "/it/diamanti-taglio-asscher.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___it",
    path: "/it/diamanti-taglio-cuscino.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___it",
    path: "/it/diamanti-taglio-smeraldo.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___it",
    path: "/it/diamanti-taglio-cuore.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___it",
    path: "/it/diamanti-taglio-marquise.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___it",
    path: "/it/diamanti-taglio-ovale.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___it",
    path: "/it/diamanti-taglio-goccia.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___it",
    path: "/it/diamanti-taglio-princess.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___it",
    path: "/it/diamanti-taglio-radiante.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___it",
    path: "/it/diamanti-rotondi.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___it",
    path: "/it/diamanti-taglio-trillion.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___it",
    path: "/it/testimonials.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___it",
    path: "/it/tipologia-di-taglio-diamante.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___it",
    path: "/it/introduzione.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___it",
    path: "/it/taglio-misto.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___it",
    path: "/it/taglio-brillante-modificato.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___it",
    path: "/it/taglio-old-mine-europeo-antico.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___it",
    path: "/it/taglio-rotondo-brillante.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___it",
    path: "/it/taglio-scalato.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"it"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/cn/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___cn",
    path: "/cn/diamondsxin-wen-bao-dao.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___cn",
    path: "/cn/guan-yu-diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___cn",
    path: "/cn/zuan-shi-jie-gou-xiang-jie.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___cn",
    path: "/cn/jiang-xiang-xiang-qing.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___cn",
    path: "/cn/jiang-xiang",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___cn",
    path: "/cn/jia-na-da-zuan-shi.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___cn",
    path: "/cn/zhi-ye-ji-hui.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___cn",
    path: "/cn/jing-du-zeng-qiang-xing-zuan-shi.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___cn",
    path: "/cn/dui-bi-zuan-shi-zheng-shu.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___cn",
    path: "/cn/cheng-ben.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___cn",
    path: "/cn/ding-hun-jie-zhi/zuan-shi-gou-mai-zhi-nan",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___cn",
    path: "/cn/zuan-shi-ke-la-chong.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___cn",
    path: "/cn/zuan-shi-zheng-shu.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___cn",
    path: "/cn/zuan-shi-jing-du-zhuan-jia-jian-yi.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___cn",
    path: "/cn/zuan-shi-jing-du-fen-ji.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___cn",
    path: "/cn/zuan-shi-jing-du.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___cn",
    path: "/cn/zuan-shi-yan-se.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___cn",
    path: "/cn/zuan-shi-qie-gong-fen-ji.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___cn",
    path: "/cn/zuan-shi-qie-ge-guo-cheng.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___cn",
    path: "/cn/zuan-shi-qie-gong.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___cn",
    path: "/cn/zuan-shi-ying-guang.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___cn",
    path: "/cn/zuan-shi-fen-ji-shi-yan-shi.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___cn",
    path: "/cn/zuan-shi-nei-han-wu.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___cn",
    path: "/cn/zuan-shi-xing-zhuang.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___cn",
    path: "/cn/ding-hun-jie-zhi/ding-hun-gou-wu-zhi-nan",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___cn",
    path: "/cn/dao-de-zuan-shi.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___cn",
    path: "/cn/zuan-shi-zhi-shi/cai-se-zuan-shi.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___cn",
    path: "/cn/ba-xin-ba-jian.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___cn",
    path: "/cn/zuan-shi-shi-ru-he-qie-ge-de.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___cn",
    path: "/cn/zuan-shi-shi-ru-he-xing-cheng-de.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___cn",
    path: "/cn/zuan-shi-zhi-shi.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___cn",
    path: "/cn/wo-men-de-gu-shi",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___cn",
    path: "/cn/ding-hun-jie-zhi/jie-zhi-chi-cun-can-zhao-biao",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___cn",
    path: "/cn/A-si-qie-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___cn",
    path: "/cn/dian-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___cn",
    path: "/cn/zu-mu-lu-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___cn",
    path: "/cn/xin-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___cn",
    path: "/cn/ma-yan-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___cn",
    path: "/cn/tuo-yuan-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___cn",
    path: "/cn/li-xing-xing-zhuang-zuan-shi.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___cn",
    path: "/cn/gong-zhu-fang-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___cn",
    path: "/cn/lei-di-en-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___cn",
    path: "/cn/yuan-xing-zuan-shi.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___cn",
    path: "/cn/san-jiao-xing-qie-gong-zuan-shi.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___cn",
    path: "/cn/gan-xie-xin.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___cn",
    path: "/cn/zuan-shi-qie-gong-lei-xing.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___cn",
    path: "/cn/jie-shao.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___cn",
    path: "/cn/hun-he-qie-gong.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___cn",
    path: "/cn/gai-liang-xing-ming-liang-shi-qie-gong.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___cn",
    path: "/cn/lao-kuang-shi-lao-ou-zhou-shi-qie-gong.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___cn",
    path: "/cn/yuan-xing-ming-liang-shi-qie-gong.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___cn",
    path: "/cn/jie-ti-shi-qie-gong.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"cn"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/no/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___no",
    path: "/no/77diamonds-press",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___no",
    path: "/no/omtrent-77-diamanter",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___no",
    path: "/no/anatomien-av-en-diamant",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___no",
    path: "/no/detaljer-om-utmerkelsene",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___no",
    path: "/no/utmerkelser",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___no",
    path: "/no/kanadiske-diamanter",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___no",
    path: "/no/karriere",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___no",
    path: "/no/-klarhetsforsterkede-diamanter",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___no",
    path: "/no/-sammenligne-diamantsertifikater",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___no",
    path: "/no/kostnad",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___no",
    path: "/no/forlovelsesringer/diamantkjoepsguide",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___no",
    path: "/no/diamantkaratvekt",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___no",
    path: "/no/diamantsertifikater",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___no",
    path: "/no/-ekspertrad-om-klarhet-i-diamanter",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___no",
    path: "/no/klassifisering-av-diamanters-klarhet",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___no",
    path: "/no/-klarhet-i-diamanter",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___no",
    path: "/no/diamantfarge",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___no",
    path: "/no/diamantslipt-gradering",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___no",
    path: "/no/diamantslipeprosessen",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___no",
    path: "/no/diamantsliping",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___no",
    path: "/no/diamantfluorescens",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___no",
    path: "/no/laboratorier-for-diamantsortering",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___no",
    path: "/no/inneslutninger-av-diamanter",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___no",
    path: "/no/diamantformer",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___no",
    path: "/no/forlovelsesringer/guide-til-kjoep-av-forlovelsesringer",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___no",
    path: "/no/etiske-diamanter",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___no",
    path: "/no/fancy-fargede-diamanter",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___no",
    path: "/no/hjertets-piler",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___no",
    path: "/no/hvordan-slipes-diamanter?",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___no",
    path: "/no/hvordan-dannes-diamanter?",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___no",
    path: "/no/diamantutdanning",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___no",
    path: "/no/var-historie",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___no",
    path: "/no/forlovelsesringer/ringstoerrelsestabell",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___no",
    path: "/no/asscherslipte-diamanter",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___no",
    path: "/no/-puteslipte-diamanter.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___no",
    path: "/no/emeraldslipte-diamanter",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___no",
    path: "/no/hjerteslipte-diamanter",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___no",
    path: "/no/marquiseslipte-diamanter",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___no",
    path: "/no/-ovalslipte-diamanter",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___no",
    path: "/no/-diamanter-i-preform",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___no",
    path: "/no/prinsesseslipte-diamanter",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___no",
    path: "/no/-diamanter-med-stralende-sliping",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___no",
    path: "/no/runde-diamanter",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___no",
    path: "/no/-trillionslipte-diamanter",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___no",
    path: "/no/uttalelser",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___no",
    path: "/no/type-diamantsliping",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___no",
    path: "/no/innledning",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___no",
    path: "/no/blandet-snitt",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___no",
    path: "/no/modifisert-briljant-klipping",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___no",
    path: "/no/old-mine-old-european-cut",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___no",
    path: "/no/-rund-briljantsliping",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___no",
    path: "/no/trinn-kutt",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"no"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/pl/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___pl",
    path: "/pl/77diamonds-dla-prasy.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___pl",
    path: "/pl/o-firmie-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___pl",
    path: "/pl/anatomia-diamentu.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___pl",
    path: "/pl/nagrody-szczegoly.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___pl",
    path: "/pl/nagrody",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___pl",
    path: "/pl/diamenty-kanadyjskie.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___pl",
    path: "/pl/praca.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___pl",
    path: "/pl/diamenty-o-poprawionej-czystosci.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___pl",
    path: "/pl/porownaj-certyfikaty-diamentow.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___pl",
    path: "/pl/koszt.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___pl",
    path: "/pl/pierscionki-zareczynowe/przewodnik-po-kupowaniu-diamentow",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___pl",
    path: "/pl/diament-waga-w-karatach.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___pl",
    path: "/pl/certyfikaty-diamentow.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___pl",
    path: "/pl/diamenty-czystosc-rada-eksperta.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___pl",
    path: "/pl/klasyfikacja-czystosci-diamentow.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___pl",
    path: "/pl/czystosc-diamentu.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___pl",
    path: "/pl/barwa-diamentu.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___pl",
    path: "/pl/klasyfikacja-szlifow-diamentow.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___pl",
    path: "/pl/diamenty-proces-szlifowania.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___pl",
    path: "/pl/szlif-diamentu.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___pl",
    path: "/pl/diamenty-fluorescencja.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___pl",
    path: "/pl/diamenty-laboratoria-oceniajace.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___pl",
    path: "/pl/diamenty-inkluzje.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___pl",
    path: "/pl/ksztalty-diamentow.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___pl",
    path: "/pl/pierscionki-zareczynowe/poradnik-kupowania-zareczynowego",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___pl",
    path: "/pl/diamenty-etyczne.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___pl",
    path: "/pl/diamenty-kolory-fantazyjne.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___pl",
    path: "/pl/hearts-arrows.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___pl",
    path: "/pl/jak-szlifowane-sa-diamenty.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___pl",
    path: "/pl/jak-powstaja-diamenty.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___pl",
    path: "/pl/edukacja-na-temat-diamentow.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___pl",
    path: "/pl/77diamonds-nasza-historia.html",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___pl",
    path: "/pl/pierscionki-zareczynowe/tabela-rozmiarow-pierscionkow",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-asscher.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-poduszka.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-szmaragdowy.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-serce.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-markiza.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-owalny.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___pl",
    path: "/pl/diamenty-szlif-gruszka.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-princessa.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-radiant.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___pl",
    path: "/pl/diamenty-okragle.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___pl",
    path: "/pl/diamenty-szlif-trillion.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___pl",
    path: "/pl/opinie.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___pl",
    path: "/pl/typy-szlifow-diamentowych.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___pl",
    path: "/pl/wprowadzenie.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___pl",
    path: "/pl/szlif-mieszany.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___pl",
    path: "/pl/szlif-brylantowy-zmodyfikowany.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___pl",
    path: "/pl/szlif-old-european-mine.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___pl",
    path: "/pl/round-brilliant-cut.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___pl",
    path: "/pl/diamenty-szlif-schodkowy.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"pl"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/pt/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___pt",
    path: "/pt/77diamantes-press.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___pt",
    path: "/pt/about-77diamantes.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___pt",
    path: "/pt/anatomy-of-a-diamond.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___pt",
    path: "/pt/awards-details.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___pt",
    path: "/pt/premios",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___pt",
    path: "/pt/canadian-diamantes.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___pt",
    path: "/pt/careers.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___pt",
    path: "/pt/clarity-enhanced-diamantes.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___pt",
    path: "/pt/compare-diamond-certificates.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___pt",
    path: "/pt/cost.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___pt",
    path: "/pt/aneis-de-noivado/guia-compra-diamantes",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___pt",
    path: "/pt/diamond-carat-weight.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___pt",
    path: "/pt/diamond-certificates.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___pt",
    path: "/pt/diamond-clarity-expert-advice.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___pt",
    path: "/pt/diamond-clarity-grading.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___pt",
    path: "/pt/diamond-clarity.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___pt",
    path: "/pt/diamond-colour.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___pt",
    path: "/pt/diamond-cut-grading.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___pt",
    path: "/pt/diamond-cut-process.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___pt",
    path: "/pt/diamond-cut.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___pt",
    path: "/pt/diamond-fluorescence.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___pt",
    path: "/pt/diamond-grading-labs.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___pt",
    path: "/pt/diamond-inclusions.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___pt",
    path: "/pt/diamond-shapes.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___pt",
    path: "/pt/aneis-de-noivado/guia-compra-aneis-de-noivado",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___pt",
    path: "/pt/ethical-diamantes.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___pt",
    path: "/pt/fancy-coloured-diamantes.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___pt",
    path: "/pt/coracaos-arrows.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___pt",
    path: "/pt/how-are-diamantes-cut.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___pt",
    path: "/pt/how-are-diamantes-formed.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___pt",
    path: "/pt/diamond-educacao.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___pt",
    path: "/pt/a-nossa-historia",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___pt",
    path: "/pt/aneis-de-noivado/chart-tamanhos-de-aneis",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___pt",
    path: "/pt/asscher-cut-diamantes.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___pt",
    path: "/pt/almofada-cut-diamantes.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___pt",
    path: "/pt/esmeralda-cut-diamantes.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___pt",
    path: "/pt/coracao-cut-diamantes.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___pt",
    path: "/pt/marquise-cut-diamantes.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___pt",
    path: "/pt/oval-cut-diamantes.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___pt",
    path: "/pt/pera-shape-diamantes.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___pt",
    path: "/pt/princesa-cut-diamantes.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___pt",
    path: "/pt/radiante-cut-diamantes.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___pt",
    path: "/pt/redondo-diamantes.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___pt",
    path: "/pt/trillion-cut-diamantes.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___pt",
    path: "/pt/testimonials.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___pt",
    path: "/pt/type-of-diamond-cuts.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___pt",
    path: "/pt/introduction.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___pt",
    path: "/pt/mixed-cut.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___pt",
    path: "/pt/modified-brilliant-cut.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___pt",
    path: "/pt/old-mine-old-european-cut.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___pt",
    path: "/pt/redondo-brilliant-cut.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___pt",
    path: "/pt/step-cut.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"pt"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/ro/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___ro",
    path: "/ro/77diamonds-press",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___ro",
    path: "/ro/aproximativ-77diamante",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___ro",
    path: "/ro/anatomia-unui-diamant",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___ro",
    path: "/ro/detalii-premii",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___ro",
    path: "/ro/premii",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___ro",
    path: "/ro/diamante-canadiene",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___ro",
    path: "/ro/cariere",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___ro",
    path: "/ro/diamante-de-claritate-imbunatatita",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___ro",
    path: "/ro/comparati-certificatele-de-diamant",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___ro",
    path: "/ro/cost",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___ro",
    path: "/ro/aneluri-de-logodna/ghid-de-cumparare-a-diamantelor",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___ro",
    path: "/ro/greutatea-in-carate-a-diamantului",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___ro",
    path: "/ro/certificate-de-diamant",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___ro",
    path: "/ro/consiliere-de-specialitate-in-materie-de-claritate-a-diamantelor",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___ro",
    path: "/ro/clasa-de-claritate-a-diamantului",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___ro",
    path: "/ro/claritate-diamant",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___ro",
    path: "/ro/culoare-diamant",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___ro",
    path: "/ro/diamant-taiat-gradat",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___ro",
    path: "/ro/procesul-de-taiere-a-diamantelor",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___ro",
    path: "/ro/diamant-taiat",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___ro",
    path: "/ro/fluorescenta-diamantului",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___ro",
    path: "/ro/laboratoare-de-clasificare-a-diamantelor",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___ro",
    path: "/ro/incluziuni-de-diamant",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___ro",
    path: "/ro/forme-de-diamant",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___ro",
    path: "/ro/aneluri-de-logodna/ghid-de-cumparaturi-pentru-logodna",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___ro",
    path: "/ro/diamante-etice",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___ro",
    path: "/ro/diamante-colorate-de-lux",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___ro",
    path: "/ro/sageti-de-inima",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___ro",
    path: "/ro/cum-se-taie-diamantele",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___ro",
    path: "/ro/cum-se-formeaza-diamantele",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___ro",
    path: "/ro/educatie-cu-diamante",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___ro",
    path: "/ro/povestea-noastra",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___ro",
    path: "/ro/aneluri-de-logodna/dimensiuni-inele-de-logodna",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___ro",
    path: "/ro/diamantele-taiate-in-forma-de-ascher",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___ro",
    path: "/ro/diamantele-taiate-in-forma-de-perna.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___ro",
    path: "/ro/diamante-taiate-smarald",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___ro",
    path: "/ro/diamante-taiate-in-forma-de-inima",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___ro",
    path: "/ro/diamante-taiate-in-forma-de-marchiza",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___ro",
    path: "/ro/diamante-taiate-oval",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___ro",
    path: "/ro/diamante-in-forma-de-para",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___ro",
    path: "/ro/diamante-cu-taietura-printesa",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___ro",
    path: "/ro/diamante-taiate-radiant",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___ro",
    path: "/ro/diamante-rotunde",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___ro",
    path: "/ro/diamantele-cu-taietura-trilionara",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___ro",
    path: "/ro/testimoniale",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___ro",
    path: "/ro/tipuri-de-diamante-taiate",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___ro",
    path: "/ro/introducere",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___ro",
    path: "/ro/mediu-taiat",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___ro",
    path: "/ro/modified-brilliant-cut",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___ro",
    path: "/ro/old-mine-old-european-cut",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___ro",
    path: "/ro/-rotund-brilliant-cut",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___ro",
    path: "/ro/pasul-de-taiere",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"ro"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/ru/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___ru",
    path: "/ru/77diamonds-pressa.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___ru",
    path: "/ru/o-nas-77-diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___ru",
    path: "/ru/anatomija-brillianta.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___ru",
    path: "/ru/nagrady-podrobnosti.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___ru",
    path: "/ru/nagrady",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___ru",
    path: "/ru/kanadskie-brillianty.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___ru",
    path: "/ru/vakansii.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___ru",
    path: "/ru/modifikacija-chistoty-brillianta.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___ru",
    path: "/ru/sravnit--sertifikaty.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___ru",
    path: "/ru/stoimost-.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___ru",
    path: "/ru/pomolvochnye-kol-ca/rukovodstvo-po-pokupke-brillianta",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___ru",
    path: "/ru/karatnyj-ves-brillianta.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___ru",
    path: "/ru/sertifikat-brillianta.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___ru",
    path: "/ru/chistota-brillianta-sovet-eksperta.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___ru",
    path: "/ru/brilliant-chistota.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___ru",
    path: "/ru/chistota-brillianta.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___ru",
    path: "/ru/cvet-brillianta.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___ru",
    path: "/ru/ocenka-brilliantovoj-ogranki.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___ru",
    path: "/ru/process-ogranki-brilliantov.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___ru",
    path: "/ru/ogranka-brillianta.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___ru",
    path: "/ru/fluorescencija-brillianta.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___ru",
    path: "/ru/laboratorii-ocenivajushhie-brillianty.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___ru",
    path: "/ru/vkljuchenija-v-brilliantah.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___ru",
    path: "/ru/forma-brillianta.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___ru",
    path: "/ru/pomolvochnye-kol-ca/rukovodstvo-po-pokupke.html",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___ru",
    path: "/ru/eticheskie-brillianty.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___ru",
    path: "/ru/brillianty-fantazijnogo-okrasa.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___ru",
    path: "/ru/serdca-i-strely.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___ru",
    path: "/ru/kak-granjat-almazy.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___ru",
    path: "/ru/kak-obrazujutsja-almazy.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___ru",
    path: "/ru/vsjo-o-brilliantah",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___ru",
    path: "/ru/our-story",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___ru",
    path: "/ru/pomolvochnye-kol-ca/tablica-razmerov.html",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-asher.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-kushon.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-izumrud.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-serdce.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-markiz.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-oval.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___ru",
    path: "/ru/brillianty-ogranki-kaplja.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-princessa.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-radiant.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___ru",
    path: "/ru/kruglye-brillianty.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___ru",
    path: "/ru/brillianty-ogranki-trillion.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___ru",
    path: "/ruotzyvy.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___ru",
    path: "/ru/tipy-ogranki-brillianta.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___ru",
    path: "/ru/vvedenie.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___ru",
    path: "/ru/smeshannaja-ogranka..html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___ru",
    path: "/ru/izmenennaja-ogranka-brilliant.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___ru",
    path: "/ru/brillianty-starinnoj-ogranki.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___ru",
    path: "/ru/brillianty-ogranki-krug.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___ru",
    path: "/rustupenchataja-ogranka.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"ru"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/es/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___es",
    path: "/es/77diamonds-prensa",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___es",
    path: "/es/sobre-77diamonds",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___es",
    path: "/es/anatomia-del-diamante",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___es",
    path: "/es/premios-detalles",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___es",
    path: "/es/premios",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___es",
    path: "/es/diamantes-canadienses",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___es",
    path: "/es/carreras",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___es",
    path: "/es/diamantes-con-claridad-mejorada",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___es",
    path: "/es/compare-certificados-de-diamantes",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___es",
    path: "/es/costo",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___es",
    path: "/es/anillos-de-compromiso/guia-de-compra-del-diamante",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___es",
    path: "/es/peso-en-quilates-del-diamante",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___es",
    path: "/es/certificados-de-diamantes",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___es",
    path: "/es/diamantes-pureza-consejos-expertos",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___es",
    path: "/es/grados-de-pureza-del-diamante",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___es",
    path: "/es/claridad-del-diamante",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___es",
    path: "/es/diamante-coloreado",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___es",
    path: "/es/clasificacion-del-corte-del-diamante",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___es",
    path: "/es/proceso-de-corte-del-diamante",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___es",
    path: "/es/corte-del-diamante",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___es",
    path: "/es/diamantes-fluorescencia",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___es",
    path: "/es/laboratorios-calificacion-diamantes",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___es",
    path: "/es/inclusiones-del-diamante",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___es",
    path: "/es/formas-del-diamantes",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___es",
    path: "/es/anillos-compromiso/guia-compra-compromiso",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___es",
    path: "/es/diamantes-eticos",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___es",
    path: "/es/diamantes-fantasia",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___es",
    path: "/es/hearts-arrows",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___es",
    path: "/es/como-se-cortan-los-diamantes",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___es",
    path: "/es/como-se-forman-los-diamantes",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___es",
    path: "/es/educacion-diamante",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___es",
    path: "/es/nuestra-historia",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___es",
    path: "/es/anillos-de-compromiso/tabla-de-tallas-de-anillos",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___es",
    path: "/es/diamantes-corte-asscher",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___es",
    path: "/es/diamantes-corte-cojin",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___es",
    path: "/es/diamantes-corte-esmeralda",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___es",
    path: "/es/diamantes-talla-corazon",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___es",
    path: "/es/diamantes-corte-marquesa",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___es",
    path: "/es/diamantes-corte-oval",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___es",
    path: "/es/diamantes-forma-pera",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___es",
    path: "/es/diamantes-corte-princesa",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___es",
    path: "/es/diamantes-corte-radiante",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___es",
    path: "/es/diamantes-redondo",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___es",
    path: "/es/diamantes-corte-triangular",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___es",
    path: "/es/testimonios",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___es",
    path: "/es/tipos-de-corte-de-diamante",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___es",
    path: "/es/introduccion",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___es",
    path: "/es/cortes-mixtos",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___es",
    path: "/es/corte-brillante-modificado",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___es",
    path: "/es/corte-antiguo-de-mina-corte-europeo",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___es",
    path: "/es/corte-brillante-redondo",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___es",
    path: "/es/corte-escalonado",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"es"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/se/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___se",
    path: "/se/77diamonds-press.html",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___se",
    path: "/se/om-77diamonds.html",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___se",
    path: "/se/uppbyggnaden-av-en-diamant.html",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___se",
    path: "/se/utmarkelser-detaljer.html",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___se",
    path: "/se/utmarkelser",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___se",
    path: "/se/kanadensiska-diamanter.html",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___se",
    path: "/se/karriar.html",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___se",
    path: "/se/klarhet-enhanced-diamanter.html",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___se",
    path: "/se/jamfor-diamant-certifikat.html",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___se",
    path: "/se/pris.html",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___se",
    path: "/se/forlovningsringar/diamant-kop-guide",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___se",
    path: "/se/diamant-karat-vikt.html",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___se",
    path: "/se/diamant-certifikat.html",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___se",
    path: "/se/diamant-klarhet-expert-rad.html",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___se",
    path: "/se/diamant-klarhets-grad.html",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___se",
    path: "/se/diamant-klarhet.html",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___se",
    path: "/se/diamant-farg.html",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___se",
    path: "/se/diamant-skarningar-gradering.html",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___se",
    path: "/se/diamant-skurning-process.html",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___se",
    path: "/se/diamant-skarningar.html",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___se",
    path: "/se/diamant-fluorescens.html",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___se",
    path: "/se/diamant-graderingar-labb.html",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___se",
    path: "/se/diamant-inneslutningar.html",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___se",
    path: "/se/diamant-form.html",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___se",
    path: "/se/forlovningsringar/forlovning-kop-guide",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___se",
    path: "/se/etiska-diamanter.html",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___se",
    path: "/se/fancy-fargade-diamanter.html",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___se",
    path: "/se/hjartats-vinklar.html",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___se",
    path: "/se/hur-ar-diamanter-skurna.html",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___se",
    path: "/se/hur-ar-diamanter-formade.html",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___se",
    path: "/se/diamant-utbildningar.html",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___se",
    path: "/se/var-story",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___se",
    path: "/se/forlovningsringar/ringstorlek",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___se",
    path: "/se/asscher-skurna-diamanter.html",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___se",
    path: "/se/kudde-skurna-diamanter.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___se",
    path: "/se/smaragd-skurna-diamanter.html",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___se",
    path: "/se/hjarta-skurna-diamanter.html",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___se",
    path: "/se/markis-skurna-diamanter.html",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___se",
    path: "/se/oval-skurna-diamanter.html",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___se",
    path: "/se/paron-skurna-diamanter.html",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___se",
    path: "/se/prinsessa-skurning-diamanter.html",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___se",
    path: "/se/stralande-skurna-diamanter.html",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___se",
    path: "/se/rund-diamanter.html",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___se",
    path: "/se/trillion-skurna-diamanter.html",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___se",
    path: "/se/kundreferenser.html",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___se",
    path: "/se/typer-av-diamant-skarningar.html",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___se",
    path: "/se/introduktion.html",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___se",
    path: "/se/mixad-skurning.html",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___se",
    path: "/se/modifierad-brilliant-skurning.html",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___se",
    path: "/se/gammal-europeisk-skurning.html",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___se",
    path: "/se/rund-stralande-skurning.html",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___se",
    path: "/se/step-skurning.html",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"se"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: diamond_45educationMW6QW1Y00NMeta?.name,
    path: "/hu/education/diamond-education",
    component: () => import("/src/pages/education/diamond-education.vue"),
    children: [
  {
    name: "54_education-diamond-education-77diamonds-press___hu",
    path: "/hu/77diamonds-press",
    meta: {"pageId":54,"aspRoute":"/Footer/Awards-and-Press.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/77diamonds-press.vue")
  },
  {
    name: "53_education-diamond-education-about-77diamonds___hu",
    path: "/hu/kabe-77gyemantrol",
    meta: {"pageId":53,"aspRoute":"/Footer/Aboutus.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/about-77diamonds.vue")
  },
  {
    name: "79_education-diamond-education-anatomy-of-a-diamond___hu",
    path: "/hu/a-gyemant-anatomiaja",
    meta: {"pageId":79,"aspRoute":"/Education/Anotomyofdmd.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/anatomy-of-a-diamond.vue")
  },
  {
    name: "70_education-diamond-education-awards-details___hu",
    path: "/hu/jutalmak-reszletei",
    meta: {"pageId":70,"aspRoute":"/Footer/PressDetails.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/awards-details.vue")
  },
  {
    name: "55_education-diamond-education-awards___hu",
    path: "/hu/jutalmak",
    meta: {"pageId":55,"aspRoute":"/Footer/Awards.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/awards.vue")
  },
  {
    name: "82_education-diamond-education-canadian-diamonds___hu",
    path: "/hu/kanadai-gyemantok",
    meta: {"pageId":82,"aspRoute":"/Education/CanadianDiamonds.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/canadian-diamonds.vue")
  },
  {
    name: "56_education-diamond-education-careers___hu",
    path: "/hu/karrierek",
    meta: {"pageId":56,"aspRoute":"/Footer/Careers.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/careers.vue")
  },
  {
    name: "87_education-diamond-education-clarity-enhanced-diamonds___hu",
    path: "/hu/klariumos-gyemantok",
    meta: {"pageId":87,"aspRoute":"/Education/Clarityenhancement.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/clarity-enhanced-diamonds.vue")
  },
  {
    name: "84_education-diamond-education-compare-diamond-certificates___hu",
    path: "/hu/gyemant-tanusitvanyok-osszehasonlitasa",
    meta: {"pageId":84,"aspRoute":"/Education/CertificateComparisions.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/compare-diamond-certificates.vue")
  },
  {
    name: "91_education-diamond-education-cost___hu",
    path: "/hu/koltseg",
    meta: {"pageId":91,"aspRoute":"/Education/Cost.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/cost.vue")
  },
  {
    name: "96_education-diamond-education-diamond-buying-guide___hu",
    path: "/hu/jegygyuruk/gyemantvasarlasi-utmutato",
    meta: {"pageId":96,"aspRoute":"/Education/DiamondBuyingGuide.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-buying-guide.vue")
  },
  {
    name: "83_education-diamond-education-diamond-carat-weight___hu",
    path: "/hu/gyemant-karat-sulya",
    meta: {"pageId":83,"aspRoute":"/Education/carat.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-carat-weight.vue")
  },
  {
    name: "85_education-diamond-education-diamond-certificates___hu",
    path: "/hu/gyemant-tanusitvanyok",
    meta: {"pageId":85,"aspRoute":"/Education/Certification.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-certificates.vue")
  },
  {
    name: "102_education-diamond-education-diamond-clarity-expert-advice___hu",
    path: "/hu/gyemant-tisztasagi-szakertoi-tanacsadas",
    meta: {"pageId":102,"aspRoute":"/Education/ExpertAdviceeyeclean.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-expert-advice.vue")
  },
  {
    name: "88_education-diamond-education-diamond-clarity-grading___hu",
    path: "/hu/gyemant-tisztasagi-osztalyzat",
    meta: {"pageId":88,"aspRoute":"/Education/ClarityGrades.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity-grading.vue")
  },
  {
    name: "86_education-diamond-education-diamond-clarity___hu",
    path: "/hu/gyemant-tisztasaga",
    meta: {"pageId":86,"aspRoute":"/Education/Clarity.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-clarity.vue")
  },
  {
    name: "89_education-diamond-education-diamond-colour___hu",
    path: "/hu/gyemant-szine",
    meta: {"pageId":89,"aspRoute":"/Education/color.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-colour.vue")
  },
  {
    name: "94_education-diamond-education-diamond-cut-grading___hu",
    path: "/hu/gyemantvagas-osztalyozasa",
    meta: {"pageId":94,"aspRoute":"/Education/CutGrading.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-grading.vue")
  },
  {
    name: "115_education-diamond-education-diamond-cut-process___hu",
    path: "/hu/gyemantvagasi-eljaras",
    meta: {"pageId":115,"aspRoute":"/Education/Polishing.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut-process.vue")
  },
  {
    name: "93_education-diamond-education-diamond-cut___hu",
    path: "/hu/gyemantvagas",
    meta: {"pageId":93,"aspRoute":"/Education/Cut.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-cut.vue")
  },
  {
    name: "104_education-diamond-education-diamond-fluorescence___hu",
    path: "/hu/gyemant-fluoreszcencia",
    meta: {"pageId":104,"aspRoute":"/Education/Fluorescence.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-fluorescence.vue")
  },
  {
    name: "109_education-diamond-education-diamond-grading-labs___hu",
    path: "/hu/gyemant-osztalyozo-laboratoriumok",
    meta: {"pageId":109,"aspRoute":"/Education/IndividualGradingLab.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-grading-labs.vue")
  },
  {
    name: "98_education-diamond-education-diamond-inclusions___hu",
    path: "/hu/gyemant-zarvanyok",
    meta: {"pageId":98,"aspRoute":"/Education/DiamondInclusionB.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-inclusions.vue")
  },
  {
    name: "90_education-diamond-education-diamond-shapes___hu",
    path: "/hu/gyemant-alakzatok",
    meta: {"pageId":90,"aspRoute":"/Education/Contour.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/diamond-shapes.vue")
  },
  {
    name: "100_education-diamond-education-engagement-buying-guide___hu",
    path: "/hu/jegygyuruk/jegygyuruvasarlasi-utmutato",
    meta: {"pageId":100,"aspRoute":"/Education/EngagementringBuyingGuide.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/engagement-buying-guide.vue")
  },
  {
    name: "101_education-diamond-education-ethical-diamonds___hu",
    path: "/hu/etikai-gyemantok",
    meta: {"pageId":101,"aspRoute":"/Education/Ethical-Diamonds.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/ethical-diamonds.vue")
  },
  {
    name: "103_education-diamond-education-fancy-coloured-diamonds___hu",
    path: "/hu/fancy-szines-gyemantok",
    meta: {"pageId":103,"aspRoute":"/Education/FancyColouredDiamonds.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/fancy-coloured-diamonds.vue")
  },
  {
    name: "106_education-diamond-education-hearts-arrows___hu",
    path: "/hu/szivek-nyilai",
    meta: {"pageId":106,"aspRoute":"/Education/HeartsandArrows.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/hearts-arrows.vue")
  },
  {
    name: "107_education-diamond-education-how-are-diamonds-cut___hu",
    path: "/hu/hogyan-csiszoljak-a-gyemantot?",
    meta: {"pageId":107,"aspRoute":"/Education/HowareDiamondsCut.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-cut.vue")
  },
  {
    name: "108_education-diamond-education-how-are-diamonds-formed___hu",
    path: "/hu/hogyan-keletkeznek-a-gyemantok?",
    meta: {"pageId":108,"aspRoute":"/Education/HowareDiamondsFormed.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/how-are-diamonds-formed.vue")
  },
  {
    name: "97_education-diamond-education___hu",
    path: "/hu/diamant-oktatas",
    meta: {"pageId":97,"aspRoute":"/Education/DiamondEducation.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/index.vue")
  },
  {
    name: "69_education-diamond-education-our-story___hu",
    path: "/hu/tortenetunk",
    meta: {"pageId":69,"aspRoute":"/Footer/OurStory.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/our-story.vue")
  },
  {
    name: "118_education-diamond-education-ring-size-chart___hu",
    path: "/hu/jegygyuruk/gyurumerettablazat",
    meta: {"pageId":118,"aspRoute":"/Education/RingSizes.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/ring-size-chart.vue")
  },
  {
    name: "80_education-diamond-education-shapes-asscher-cut-diamonds___hu",
    path: "/hu/asscher-csiszolasu-gyemantok",
    meta: {"pageId":80,"aspRoute":"/Education/Asscher.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/asscher-cut-diamonds.vue")
  },
  {
    name: "92_education-diamond-education-shapes-cushion-cut-diamonds___hu",
    path: "/hu/parnavagott-gyemantok.html",
    meta: {"pageId":92,"aspRoute":"/Education/Cushion.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/cushion-cut-diamonds.vue")
  },
  {
    name: "99_education-diamond-education-shapes-emerald-cut-diamonds___hu",
    path: "/hu/emerald-csiszolasu-gyemantok",
    meta: {"pageId":99,"aspRoute":"/Education/Emerald.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/emerald-cut-diamonds.vue")
  },
  {
    name: "105_education-diamond-education-shapes-heart-cut-diamonds___hu",
    path: "/hu/szivbe-vagott-gyemantok",
    meta: {"pageId":105,"aspRoute":"/Education/Heart.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/heart-cut-diamonds.vue")
  },
  {
    name: "111_education-diamond-education-shapes-marquise-cut-diamonds___hu",
    path: "/hu/marquise-csiszolasu-gyemantok",
    meta: {"pageId":111,"aspRoute":"/Education/Marquise.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/marquise-cut-diamonds.vue")
  },
  {
    name: "113_education-diamond-education-shapes-oval-cut-diamonds___hu",
    path: "/hu/ovalis-csiszolasu-gyemantok",
    meta: {"pageId":113,"aspRoute":"/Education/Oval.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/oval-cut-diamonds.vue")
  },
  {
    name: "114_education-diamond-education-shapes-pear-shape-diamonds___hu",
    path: "/hu/gyemant-alaku-gyemantok",
    meta: {"pageId":114,"aspRoute":"/Education/Pear.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/pear-shape-diamonds.vue")
  },
  {
    name: "116_education-diamond-education-shapes-princess-cut-diamonds___hu",
    path: "/hu/princess-csiszolt-gyemantok",
    meta: {"pageId":116,"aspRoute":"/Education/Princes.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/princess-cut-diamonds.vue")
  },
  {
    name: "117_education-diamond-education-shapes-radiant-cut-diamonds___hu",
    path: "/hu/radians-csiszolasu-gyemantok",
    meta: {"pageId":117,"aspRoute":"/Education/Radiant.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/radiant-cut-diamonds.vue")
  },
  {
    name: "120_education-diamond-education-shapes-round-diamonds___hu",
    path: "/hu/kerek-gyemantok",
    meta: {"pageId":120,"aspRoute":"/Education/Round.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/round-diamonds.vue")
  },
  {
    name: "123_education-diamond-education-shapes-trillion-cut-diamonds___hu",
    path: "/hu/trillio-csiszolasu-gyemantok",
    meta: {"pageId":123,"aspRoute":"/Education/Trillion.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/shapes/trillion-cut-diamonds.vue")
  },
  {
    name: "77_education-diamond-education-testimonials___hu",
    path: "/hu/beszamolok",
    meta: {"pageId":77,"aspRoute":"/Footer/Testimonials.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/testimonials.vue")
  },
  {
    name: "95_education-diamond-education-type-of-diamond-cuts___hu",
    path: "/hu/gyemantvagasok-tipusa",
    meta: {"pageId":95,"aspRoute":"/Education/CutGradingdiamond.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/index.vue")
  },
  {
    name: "110_education-diamond-education-type-of-diamond-cuts-introduction___hu",
    path: "/hu/bevezetes",
    meta: {"pageId":110,"aspRoute":"/Education/Introduction.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/introduction.vue")
  },
  {
    name: "112_education-diamond-education-type-of-diamond-cuts-mixed-cut___hu",
    path: "/hu/kevert-vagas",
    meta: {"pageId":112,"aspRoute":"/Education/MixedCuts.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/mixed-cut.vue")
  },
  {
    name: "81_education-diamond-education-type-of-diamond-cuts-modified-brilliant-cut___hu",
    path: "/hu/modositott-brilians-vagas",
    meta: {"pageId":81,"aspRoute":"/Education/Briliantcut.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/modified-brilliant-cut.vue")
  },
  {
    name: "124_education-diamond-education-type-of-diamond-cuts-old-mine-old-european-cut___hu",
    path: "/hu/old-mine-old-european-cut",
    meta: {"pageId":124,"aspRoute":"/Education/Vintagecuts.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/old-mine-old-european-cut.vue")
  },
  {
    name: "121_education-diamond-education-type-of-diamond-cuts-round-brilliant-cut___hu",
    path: "/hu/kerek-brilians-vagas",
    meta: {"pageId":121,"aspRoute":"/Education/Roundcut.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/round-brilliant-cut.vue")
  },
  {
    name: "122_education-diamond-education-type-of-diamond-cuts-step-cut___hu",
    path: "/hu/lepes-vagas",
    meta: {"pageId":122,"aspRoute":"/Education/Stepcuts.aspx","locale":"hu"},
    component: () => import("/src/pages/education/diamond-education/type-of-diamond-cuts/step-cut.vue")
  }
]
  },
  {
    name: "enter-our-prize-draw___ar",
    path: "/ar/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___bg",
    path: "/bg/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___cs",
    path: "/cs/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___dk",
    path: "/dk/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___nl",
    path: "/nl/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___en",
    path: "/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___fi",
    path: "/fi/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___fr",
    path: "/fr/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___de",
    path: "/de/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___it",
    path: "/it/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___cn",
    path: "/cn/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___no",
    path: "/no/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___pl",
    path: "/pl/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___pt",
    path: "/pt/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___ro",
    path: "/ro/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___ru",
    path: "/ru/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___es",
    path: "/es/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___se",
    path: "/se/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "enter-our-prize-draw___hu",
    path: "/hu/enter-our-prize-draw",
    component: () => import("/src/pages/enter-our-prize-draw.vue")
  },
  {
    name: "19_failed___ar",
    path: "/ar/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"ar"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___bg",
    path: "/bg/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"bg"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___cs",
    path: "/cs/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"cs"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___dk",
    path: "/dk/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"dk"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___nl",
    path: "/nl/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"nl"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___en",
    path: "/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"en"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___fi",
    path: "/fi/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"fi"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___fr",
    path: "/fr/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"fr"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___de",
    path: "/de/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"de"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___it",
    path: "/it/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"it"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___cn",
    path: "/cn/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"cn"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___no",
    path: "/no/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"no"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___pl",
    path: "/pl/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"pl"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___pt",
    path: "/pt/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"pt"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___ro",
    path: "/ro/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"ro"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___ru",
    path: "/ru/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"ru"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___es",
    path: "/es/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"es"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___se",
    path: "/se/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"se"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "19_failed___hu",
    path: "/hu/failed.aspx",
    meta: {"pageId":19,"aspRoute":"/failed.aspx","locale":"hu"},
    component: () => import("/src/pages/failed.vue")
  },
  {
    name: "61_finance-information___ar",
    path: "/ar/finance-information",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"ar"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___bg",
    path: "/bg/finansova-informaciya",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"bg"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___cs",
    path: "/cs/financni-informace",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"cs"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___dk",
    path: "/dk/afbetaling.html",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"dk"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___nl",
    path: "/nl/financiering-informatie",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"nl"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___en",
    path: "/finance-information",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"en"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___fi",
    path: "/fi/rahoitustiedot",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"fi"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___fr",
    path: "/fr/financement-information",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"fr"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___de",
    path: "/de/finanzinformationen",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"de"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___it",
    path: "/it/informazioni-finanziamento",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"it"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___cn",
    path: "/cn/dai-kuan-xin-xi",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"cn"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___no",
    path: "/no/finansinformasjon",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"no"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___pl",
    path: "/pl/finansowanie.html",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"pl"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___pt",
    path: "/pt/finance-information",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"pt"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___ro",
    path: "/ro/informatii-financiare",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"ro"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___ru",
    path: "/ru/finansovaya-informaciya",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"ru"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___es",
    path: "/es/informacion-financiera",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"es"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___se",
    path: "/se/finansieringsinformation",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"se"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "61_finance-information___hu",
    path: "/hu/-penzugyi-informaciok",
    meta: {"pageId":61,"aspRoute":"/Footer/Finance.aspx","locale":"hu"},
    component: () => import("/src/pages/finance-information.vue")
  },
  {
    name: "761_forgot-password___ar",
    path: "/ar/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"ar"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___bg",
    path: "/bg/zabravena-parola",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"bg"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___cs",
    path: "/cs/zapomnel-jsem-heslo",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"cs"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___dk",
    path: "/dk/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"dk"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___nl",
    path: "/nl/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"nl"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___en",
    path: "/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"en"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___fi",
    path: "/fi/unohdin-salasanan",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"fi"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___fr",
    path: "/fr/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"fr"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___de",
    path: "/de/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"de"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___it",
    path: "/it/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"it"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___cn",
    path: "/cn/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"cn"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___no",
    path: "/no/glemte-passord",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"no"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___pl",
    path: "/pl/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"pl"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___pt",
    path: "/pt/esqueci-me-da-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"pt"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___ro",
    path: "/ro/a-uitat-parola",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"ro"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___ru",
    path: "/ru/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"ru"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___es",
    path: "/es/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"es"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___se",
    path: "/se/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"se"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "761_forgot-password___hu",
    path: "/hu/forgot-password",
    meta: {"pageId":761,"aspRoute":"/NewApp/Default.aspx?app=forgot-password","locale":"hu"},
    component: () => import("/src/pages/forgot-password.vue")
  },
  {
    name: "620_gemstones-emerald-guide___ar",
    path: "/ar/alahgar-alkarima/alzomrod/dalil",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"ar"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___bg",
    path: "/bg/sk-pocenni-kam-ni/emerald/p-tevoditel",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"bg"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___cs",
    path: "/cs/kameny/emerald/pruvodce",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"cs"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___dk",
    path: "/dk/delsten/smaragder/guide",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"dk"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___nl",
    path: "/nl/edelstenen/smaragd/handleiding",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"nl"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___en",
    path: "/gemstones/emerald/guide",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"en"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___fi",
    path: "/fi/jalokivet/emerald/opas",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"fi"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___fr",
    path: "/fr/pierresprecieuses/emeraude/guide",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"fr"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___de",
    path: "/de/edelsteine/smaragd/guide",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"de"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___it",
    path: "/it/pietre-preziose/smeraldo/guida",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"it"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___cn",
    path: "/cn/bao-shi/zu-mu-lv/zhi-nan",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"cn"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___no",
    path: "/no/-edelstener/emerald/guide",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"no"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___pl",
    path: "/pl/kamienie-szlachetne/szmaragd/przewodnik",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"pl"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___pt",
    path: "/pt/pedras-preciosas/esmeralda/guia",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"pt"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___ro",
    path: "/ro/piese-pretioase/emerald/ghid",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"ro"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___ru",
    path: "/ru/dragocennye-kamni/izumrudy/rukovodstvo",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"ru"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___es",
    path: "/es/piedras-preciosas/esmeralda/gua",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"es"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___se",
    path: "/se/adelstenar/smaragder/guide",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"se"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "620_gemstones-emerald-guide___hu",
    path: "/hu/gyemantkovek/emerald/utmutato",
    meta: {"pageId":620,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=emerald","locale":"hu"},
    component: () => import("/src/pages/gemstones/emerald/guide.vue")
  },
  {
    name: "617_gemstones-guide___ar",
    path: "/ar/alahgar-alkarima/dalil",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"ar"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___bg",
    path: "/bg/sk-pocenni-kam-ni/p-tevoditel",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"bg"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___cs",
    path: "/cs/pruvodce-drahymi-kameny",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"cs"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___dk",
    path: "/dk/delsten/guide",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"dk"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___nl",
    path: "/nl/edelstenen/handleiding",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"nl"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___en",
    path: "/gemstones/guide",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"en"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___fi",
    path: "/fi/jalokivet/opas",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"fi"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___fr",
    path: "/fr/pierresprecieuses/guide",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"fr"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___de",
    path: "/de/edelsteine/guide",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"de"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___it",
    path: "/it/pietre-preziose/guida",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"it"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___cn",
    path: "/cn/bao-shi/zhi-nan",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"cn"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___no",
    path: "/no/-edelstener/guide",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"no"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___pl",
    path: "/pl/kamienie-szlachetne/przewodnik",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"pl"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___pt",
    path: "/pt/pedras-preciosas/guia",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"pt"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___ro",
    path: "/ro/piese-pretioase/ghid",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"ro"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___ru",
    path: "/ru/dragocennye-kamni/rukovodstvo",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"ru"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___es",
    path: "/es/piedras-preciosas/gua",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"es"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___se",
    path: "/se/adelstenar/guide",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"se"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "617_gemstones-guide___hu",
    path: "/hu/gyemantkovek/utmutato",
    meta: {"pageId":617,"aspRoute":"/Education/GemstonesGuides.aspx","locale":"hu"},
    component: () => import("/src/pages/gemstones/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___ar",
    path: "/ar/alahgar-alkarima/alyaqout/dalil",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"ar"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___bg",
    path: "/bg/sk-pocenni-kam-ni/rubi/r-kovodstvo",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"bg"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___cs",
    path: "/cs/kameny/ruby/pruvodce",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"cs"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___dk",
    path: "/dk/delsten/rubin/guide",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"dk"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___nl",
    path: "/nl/edelstenen/robijn/handleiding",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"nl"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___en",
    path: "/gemstones/ruby/guide",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"en"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___fi",
    path: "/fi/jalokivet/ruby/opas",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"fi"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___fr",
    path: "/fr/pierresprecieuses/rubis/guide",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"fr"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___de",
    path: "/de/edelsteine/rubin/guide",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"de"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___it",
    path: "/it/pietre-preziose/rubino/guida",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"it"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___cn",
    path: "/cn/bao-shi/hong-bao-shi/zhi-nan",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"cn"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___no",
    path: "/no/-edelstener/ruby/guide",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"no"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___pl",
    path: "/pl/kamienie-szlachetne/rubin/przewodnik",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"pl"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___pt",
    path: "/pt/pedras-preciosas/rubi/guia",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"pt"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___ro",
    path: "/ro/pietre-pretioase/ruby/ghid",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"ro"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___ru",
    path: "/ru/dragocennye-kamni/rubiny/rukovodstvo",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"ru"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___es",
    path: "/es/piedras-preciosas/rub/gua",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"es"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___se",
    path: "/se/adelstenar/rubiner/guide",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"se"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "618_gemstones-ruby-guide___hu",
    path: "/hu/gyemantkovek/rubin/utmutato",
    meta: {"pageId":618,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=ruby","locale":"hu"},
    component: () => import("/src/pages/gemstones/ruby/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___ar",
    path: "/ar/alahgar-alkarima/alzfir/dalil",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"ar"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___bg",
    path: "/bg/sk-pocenni-kam-ni/sapfir/r-kovodstvo",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"bg"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___cs",
    path: "/cs/drahokamy/safir/pruvodce",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"cs"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___dk",
    path: "/dk/delsten/safirer/guide",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"dk"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___nl",
    path: "/nl/edelstenen/saffier/handleiding",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"nl"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___en",
    path: "/gemstones/sapphire/guide",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"en"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___fi",
    path: "/fi/jalokivet/safiiri/opas",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"fi"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___fr",
    path: "/fr/pierresprecieuses/saphire/guide",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"fr"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___de",
    path: "/de/edelsteine/saphir/guide",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"de"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___it",
    path: "/it/pietre-preziose/zaffiro/guida",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"it"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___cn",
    path: "/cn/bao-shi/lan-bao-shi/zhi-nan",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"cn"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___no",
    path: "/no/-edelstener/-safir/guide",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"no"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___pl",
    path: "/pl/kamienie-szlachetne/szafir/przewodnik",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"pl"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___pt",
    path: "/pt/pedras-preciosas/sapphire/guia",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"pt"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___ro",
    path: "/ro/pietre-pretioase/sapfir/ghid",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"ro"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___ru",
    path: "/ru/dragocennye-kamni/sapfiry/rukovodstvo",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"ru"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___es",
    path: "/es/piedras-preciosas/zafiro/gua",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"es"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___se",
    path: "/se/adelstenar/safirer/guide",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"se"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "619_gemstones-sapphire-guide___hu",
    path: "/hu/gyemantkovek/szafir/utmutato",
    meta: {"pageId":619,"aspRoute":"/Education/GemstonesGuides.aspx?gemtype=sapphire","locale":"hu"},
    component: () => import("/src/pages/gemstones/sapphire/guide.vue")
  },
  {
    name: "43_here-comes-the-suprise___ar",
    path: "/ar/haa-qad-att-almofaagaa",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"ar"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___bg",
    path: "/bg/tuk-idva-iznenadata",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"bg"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___cs",
    path: "/cs/-tady-prichazi-prekvapeni",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"cs"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___dk",
    path: "/dk/here-comes-the-suprise",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"dk"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___nl",
    path: "/nl/hier-komt-de-verrassing",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"nl"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___en",
    path: "/here-comes-the-suprise",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"en"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___fi",
    path: "/fi/tassa-tulee-yllatys",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"fi"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___fr",
    path: "/fr/voici-la-surprise",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"fr"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___de",
    path: "/de/here-comes-the-suprise",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"de"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___it",
    path: "/it/arriva-la-sorpresa",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"it"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___cn",
    path: "/cn/zhe-li-you-jing-xi",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"cn"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___no",
    path: "/no/na-kommer-overraskelsen",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"no"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___pl",
    path: "/pl/przygotuj-sie-na-niespodzianke",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"pl"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___pt",
    path: "/pt/here-comes-the-suprise",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"pt"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___ro",
    path: "/ro/aici-vine-surpriza",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"ro"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___ru",
    path: "/ru/a-vot-i-sjurpriz",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"ru"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___es",
    path: "/es/aqui-viene-la-sorpresa",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"es"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___se",
    path: "/se/har-kommer-overraskningen",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"se"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "43_here-comes-the-suprise___hu",
    path: "/hu/es-most-jon-a-meglepetes",
    meta: {"pageId":43,"aspRoute":"/SEOPages/Infographic.aspx","locale":"hu"},
    component: () => import("/src/pages/here-comes-the-suprise.vue")
  },
  {
    name: "237_impressum___ar",
    path: "/ar/idaart-attahreer.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"ar"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___bg",
    path: "/bg/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"bg"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___cs",
    path: "/cs/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"cs"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___dk",
    path: "/dk/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"dk"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___nl",
    path: "/nl/nieuws-contact.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"nl"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___en",
    path: "/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"en"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___fi",
    path: "/fi/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"fi"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___fr",
    path: "/fr/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"fr"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___de",
    path: "/de/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"de"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___it",
    path: "/it/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"it"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___cn",
    path: "/cn/ban-ben-shuo-ming.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"cn"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___no",
    path: "/no/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"no"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___pl",
    path: "/pl/stopka-redakcyjna.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"pl"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___pt",
    path: "/pt/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"pt"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___ro",
    path: "/ro/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"ro"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___ru",
    path: "/ru/dannye.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"ru"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___es",
    path: "/es/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"es"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___se",
    path: "/se/impressum.html",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"se"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "237_impressum___hu",
    path: "/hu/impressum",
    meta: {"pageId":237,"aspRoute":"/impressum.aspx","locale":"hu"},
    component: () => import("/src/pages/impressum.vue")
  },
  {
    name: "22_index___ar",
    path: "/ar",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"ar"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___bg",
    path: "/bg",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"bg"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___cs",
    path: "/cs",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"cs"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___dk",
    path: "/dk",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"dk"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___nl",
    path: "/nl",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"nl"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___en",
    path: "/",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"en"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___fi",
    path: "/fi",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"fi"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___fr",
    path: "/fr",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"fr"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___de",
    path: "/de",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"de"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___it",
    path: "/it",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"it"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___cn",
    path: "/cn",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"cn"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___no",
    path: "/no",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"no"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___pl",
    path: "/pl",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"pl"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___pt",
    path: "/pt",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"pt"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___ro",
    path: "/ro",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"ro"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___ru",
    path: "/ru",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"ru"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___es",
    path: "/es",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"es"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___se",
    path: "/se",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"se"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "22_index___hu",
    path: "/hu",
    meta: {"pageId":22,"aspRoute":"/Default.aspx","locale":"hu"},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "621_lab-grown-diamonds___ar",
    path: "/ar/almas-almostnbt-fi-almokhtbr",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"ar"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___bg",
    path: "/bg/otgledani-v-laboratoriya-diamanti",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"bg"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___cs",
    path: "/cs/laboratorne-pestovane-diamanty",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"cs"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___dk",
    path: "/dk/laboratoriefremstillede-diamanter",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"dk"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___nl",
    path: "/nl/laboratorium-gekweekte-diamanten/koop",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"nl"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___en",
    path: "/lab-grown-diamonds",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"en"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___fi",
    path: "/fi/laboratoriossa-kasvatetut-timantit",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"fi"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___fr",
    path: "/fr/diamant-de-labo",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"fr"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___de",
    path: "/de/im-labor-gezchtete-diamanten",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"de"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___it",
    path: "/it/diamanti-creati-laboratorio",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"it"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___cn",
    path: "/cn/shi-yan-shi-pei-yu-zuan-shi",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"cn"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___no",
    path: "/no/-laboratoriedyrkede-diamanter",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"no"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___pl",
    path: "/pl/diamenty-syntetyczne",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"pl"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___pt",
    path: "/pt/lab-grown-diamantes",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"pt"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___ro",
    path: "/ro/diamante-cultivate-in-laborator",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"ro"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___ru",
    path: "/ru/iskusstvennye-brillianty",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"ru"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___es",
    path: "/es/diamantes-de-laboratorio",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"es"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___se",
    path: "/se/labbodlade-diamanter",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"se"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "621_lab-grown-diamonds___hu",
    path: "/hu/laborban-termesztett-gyemantok",
    meta: {"pageId":621,"aspRoute":"/Education/LabgrownGuides.aspx","locale":"hu"},
    component: () => import("/src/pages/lab-grown-diamonds.vue")
  },
  {
    name: "752_login___ar",
    path: "/ar/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"ar"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___bg",
    path: "/bg/-vkhod",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"bg"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___cs",
    path: "/cs/prihlaseni",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"cs"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___dk",
    path: "/dk/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"dk"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___nl",
    path: "/nl/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"nl"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___en",
    path: "/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"en"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___fi",
    path: "/fi/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"fi"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___fr",
    path: "/fr/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"fr"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___de",
    path: "/de/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"de"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___it",
    path: "/it/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"it"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___cn",
    path: "/cn/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"cn"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___no",
    path: "/no/logg-inn",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"no"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___pl",
    path: "/pl/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"pl"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___pt",
    path: "/pt/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"pt"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___ro",
    path: "/ro/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"ro"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___ru",
    path: "/ru/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"ru"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___es",
    path: "/es/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"es"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___se",
    path: "/se/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"se"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "752_login___hu",
    path: "/hu/login",
    meta: {"pageId":752,"aspRoute":"/NewApp/Default.aspx?app=login","locale":"hu"},
    component: () => import("/src/pages/login.vue")
  },
  {
    name: "logout___ar",
    path: "/ar/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___bg",
    path: "/bg/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___cs",
    path: "/cs/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___dk",
    path: "/dk/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___nl",
    path: "/nl/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___en",
    path: "/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___fi",
    path: "/fi/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___fr",
    path: "/fr/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___de",
    path: "/de/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___it",
    path: "/it/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___cn",
    path: "/cn/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___no",
    path: "/no/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___pl",
    path: "/pl/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___pt",
    path: "/pt/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___ro",
    path: "/ro/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___ru",
    path: "/ru/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___es",
    path: "/es/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___se",
    path: "/se/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "logout___hu",
    path: "/hu/logout",
    component: () => import("/src/pages/logout.vue")
  },
  {
    name: "685_loose-gemstones___ar",
    path: "/ar/alahgar-alkarima",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"ar"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___bg",
    path: "/bg/sk-pocenni-kam-ni",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"bg"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___cs",
    path: "/cs/drahokamy",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"cs"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___dk",
    path: "/dk/aedelsten",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"dk"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___nl",
    path: "/nl/edelstenen",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"nl"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___en",
    path: "/gemstones",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"en"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___fi",
    path: "/fi/jalokivet",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"fi"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___fr",
    path: "/fr/les-pierres-precieuses",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"fr"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___de",
    path: "/de/edelsteine",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"de"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___it",
    path: "/it/pietre-preziose",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"it"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___cn",
    path: "/cn/bao-shi",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"cn"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___no",
    path: "/no/-edelsteiner",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"no"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___pl",
    path: "/pl/kamienie-szlachetne",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"pl"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___pt",
    path: "/pt/pedras-preciosas",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"pt"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___ro",
    path: "/ro/pietre-pretioase",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"ro"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___ru",
    path: "/ru/dragotsennyye-kamni",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"ru"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___es",
    path: "/es/piedras-preciosas",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"es"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___se",
    path: "/se/adelstenar",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"se"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "685_loose-gemstones___hu",
    path: "/hu/-dragakovek",
    meta: {"pageId":685,"aspRoute":"/Gemstones.aspx","locale":"hu"},
    component: () => import("/src/pages/loose-gemstones.vue")
  },
  {
    name: "74_offers___ar",
    path: "/ar/alaroud.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"ar"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___bg",
    path: "/bg/oferti",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"bg"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___cs",
    path: "/cs/nabidky",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"cs"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___dk",
    path: "/dk/tilbud.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"dk"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___nl",
    path: "/nl/aanbiedingen.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"nl"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___en",
    path: "/offers.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"en"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___fi",
    path: "/fi/tarjoukset",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"fi"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___fr",
    path: "/fr/offres.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"fr"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___de",
    path: "/de/angebote.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"de"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___it",
    path: "/it/offerte.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"it"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___cn",
    path: "/cn/you-hui.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"cn"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___no",
    path: "/no/tilbud",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"no"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___pl",
    path: "/pl/oferty.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"pl"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___pt",
    path: "/pt/offers.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"pt"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___ro",
    path: "/ro/oferte",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"ro"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___ru",
    path: "/ru/predlozhenija.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"ru"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___es",
    path: "/es/ofertas",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"es"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___se",
    path: "/se/erbjudanden.html",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"se"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "74_offers___hu",
    path: "/hu/ajanlatok",
    meta: {"pageId":74,"aspRoute":"/Footer/Specialoffers.aspx","locale":"hu"},
    component: () => import("/src/pages/offers.vue")
  },
  {
    name: "737_our-ethics___ar",
    path: "/ar/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"ar"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___bg",
    path: "/bg/nashata-etika",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"bg"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___cs",
    path: "/cs/nase-etika",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"cs"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___dk",
    path: "/dk/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"dk"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___nl",
    path: "/nl/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"nl"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___en",
    path: "/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"en"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___fi",
    path: "/fi/eettiset-periaatteemme",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"fi"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___fr",
    path: "/fr/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"fr"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___de",
    path: "/de/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"de"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___it",
    path: "/it/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"it"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___cn",
    path: "/cn/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"cn"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___no",
    path: "/no/-var-etikk",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"no"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___pl",
    path: "/pl/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"pl"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___pt",
    path: "/pt/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"pt"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___ro",
    path: "/ro/etica-noastra",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"ro"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___ru",
    path: "/ru/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"ru"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___es",
    path: "/es/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"es"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___se",
    path: "/se/our-ethics",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"se"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "737_our-ethics___hu",
    path: "/hu/az-etikank",
    meta: {"pageId":737,"aspRoute":"/NewApp/Default.aspx?app=responsibility","locale":"hu"},
    component: () => import("/src/pages/our-ethics.vue")
  },
  {
    name: "78_our-promises___ar",
    path: "/ar/our-promises",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"ar"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___bg",
    path: "/bg/nashite-obeshchaniya",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"bg"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___cs",
    path: "/cs/nase-sliby",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"cs"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___dk",
    path: "/dk/vores-loefter",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"dk"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___nl",
    path: "/nl/onze-beloftes",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"nl"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___en",
    path: "/our-promises",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"en"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___fi",
    path: "/fi/lupauksistamme",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"fi"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___fr",
    path: "/fr/nos-promesses",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"fr"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___de",
    path: "/de/unsere-versprechen",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"de"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___it",
    path: "/it/nostre-promesse",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"it"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___cn",
    path: "/cn/our-promises",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"cn"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___no",
    path: "/no/loeftene-vare",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"no"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___pl",
    path: "/pl/gwarancjanajlepszejceny.html",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"pl"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___pt",
    path: "/pt/as-nossas-promessas",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"pt"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___ro",
    path: "/ro/-promisiunile-noastre",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"ro"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___ru",
    path: "/ru/our-promises",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"ru"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___es",
    path: "/es/nuestras-promesas",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"es"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___se",
    path: "/se/vara-loften",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"se"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "78_our-promises___hu",
    path: "/hu/az-igereteinket",
    meta: {"pageId":78,"aspRoute":"/Footer/The-seven-guarantees.aspx","locale":"hu"},
    component: () => import("/src/pages/our-promises.vue")
  },
  {
    name: "14_page-not-found___ar",
    path: "/ar/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"ar"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___bg",
    path: "/bg/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"bg"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___cs",
    path: "/cs/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"cs"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___dk",
    path: "/dk/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"dk"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___nl",
    path: "/nl/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"nl"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___en",
    path: "/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"en"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___fi",
    path: "/fi/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"fi"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___fr",
    path: "/fr/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"fr"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___de",
    path: "/de/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"de"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___it",
    path: "/it/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"it"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___cn",
    path: "/cn/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"cn"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___no",
    path: "/no/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"no"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___pl",
    path: "/pl/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"pl"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___pt",
    path: "/pt/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"pt"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___ro",
    path: "/ro/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"ro"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___ru",
    path: "/ru/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"ru"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___es",
    path: "/es/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"es"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___se",
    path: "/se/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"se"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "14_page-not-found___hu",
    path: "/hu/notFound.aspx",
    meta: {"pageId":14,"aspRoute":"/notFound.aspx","locale":"hu"},
    component: () => import("/src/pages/page-not-found.vue")
  },
  {
    name: "payments-return-card___ar",
    path: "/ar/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___bg",
    path: "/bg/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___cs",
    path: "/cs/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___dk",
    path: "/dk/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___nl",
    path: "/nl/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___en",
    path: "/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___fi",
    path: "/fi/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___fr",
    path: "/fr/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___de",
    path: "/de/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___it",
    path: "/it/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___cn",
    path: "/cn/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___no",
    path: "/no/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___pl",
    path: "/pl/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___pt",
    path: "/pt/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___ro",
    path: "/ro/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___ru",
    path: "/ru/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___es",
    path: "/es/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___se",
    path: "/se/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-card___hu",
    path: "/hu/payments-return/card",
    component: () => import("/src/pages/payments-return/card.vue")
  },
  {
    name: "payments-return-splitit___ar",
    path: "/ar/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___bg",
    path: "/bg/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___cs",
    path: "/cs/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___dk",
    path: "/dk/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___nl",
    path: "/nl/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___en",
    path: "/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___fi",
    path: "/fi/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___fr",
    path: "/fr/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___de",
    path: "/de/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___it",
    path: "/it/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___cn",
    path: "/cn/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___no",
    path: "/no/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___pl",
    path: "/pl/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___pt",
    path: "/pt/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___ro",
    path: "/ro/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___ru",
    path: "/ru/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___es",
    path: "/es/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___se",
    path: "/se/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-splitit___hu",
    path: "/hu/payments-return/splitit",
    component: () => import("/src/pages/payments-return/splitit.vue")
  },
  {
    name: "payments-return-v12___ar",
    path: "/ar/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___bg",
    path: "/bg/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___cs",
    path: "/cs/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___dk",
    path: "/dk/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___nl",
    path: "/nl/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___en",
    path: "/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___fi",
    path: "/fi/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___fr",
    path: "/fr/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___de",
    path: "/de/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___it",
    path: "/it/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___cn",
    path: "/cn/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___no",
    path: "/no/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___pl",
    path: "/pl/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___pt",
    path: "/pt/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___ro",
    path: "/ro/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___ru",
    path: "/ru/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___es",
    path: "/es/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___se",
    path: "/se/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "payments-return-v12___hu",
    path: "/hu/payments-return/v12",
    component: () => import("/src/pages/payments-return/v12.vue")
  },
  {
    name: "paypal-return___ar",
    path: "/ar/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___bg",
    path: "/bg/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___cs",
    path: "/cs/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___dk",
    path: "/dk/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___nl",
    path: "/nl/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___en",
    path: "/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___fi",
    path: "/fi/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___fr",
    path: "/fr/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___de",
    path: "/de/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___it",
    path: "/it/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___cn",
    path: "/cn/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___no",
    path: "/no/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___pl",
    path: "/pl/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___pt",
    path: "/pt/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___ro",
    path: "/ro/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___ru",
    path: "/ru/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___es",
    path: "/es/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___se",
    path: "/se/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "paypal-return___hu",
    path: "/hu/paypal-return",
    component: () => import("/src/pages/paypal-return.vue")
  },
  {
    name: "751_pearls-guide___ar",
    path: "/ar/lali/dalil",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"ar"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___bg",
    path: "/bg/perli/r-kovodstvo",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"bg"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___cs",
    path: "/cs/perly/pruvodce",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"cs"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___dk",
    path: "/dk/perler/guide",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"dk"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___nl",
    path: "/nl/parels/gids",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"nl"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___en",
    path: "/pearls/guide",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"en"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___fi",
    path: "/fi/helmia/opas",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"fi"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___fr",
    path: "/fr/perles/guide",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"fr"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___de",
    path: "/de/leitfaden/perlen",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"de"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___it",
    path: "/it/perle/guida",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"it"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___cn",
    path: "/cn/zhenzhu/zhi-nan",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"cn"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___no",
    path: "/no/perler/guide",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"no"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___pl",
    path: "/pl/perla/przewodnik",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"pl"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___pt",
    path: "/pt/perolas/guia",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"pt"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___ro",
    path: "/ro/perle/ghid",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"ro"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___ru",
    path: "/ru/zhemchug/rukovodstvo",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"ru"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___es",
    path: "/es/perlas/guia",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"es"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___se",
    path: "/se/parlor/guide",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"se"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "751_pearls-guide___hu",
    path: "/hu/gyongyok/utmutato",
    meta: {"pageId":751,"aspRoute":"/Education/PearlGuide.aspx","locale":"hu"},
    component: () => import("/src/pages/pearls/guide.vue")
  },
  {
    name: "72_privacy-77diamonds___ar",
    path: "/ar/khasousya-77daaymoundz.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"ar"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___bg",
    path: "/bg/zashchita-na-lichnite-danni-77diamonds",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"bg"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___cs",
    path: "/cs/privacy-77diamonds",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"cs"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___dk",
    path: "/dk/privatliv-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"dk"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___nl",
    path: "/nl/privacy-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"nl"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___en",
    path: "/privacy-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"en"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___fi",
    path: "/fi/privacy-77diamonds",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"fi"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___fr",
    path: "/fr/confidentialite-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"fr"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___de",
    path: "/de/datenschutz-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"de"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___it",
    path: "/it/privacy-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"it"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___cn",
    path: "/cn/yin-si-diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"cn"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___no",
    path: "/no/-personvern-77diamanter",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"no"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___pl",
    path: "/pl/77diamonds-prywatnosc.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"pl"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___pt",
    path: "/pt/privacy-77diamantes.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"pt"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___ro",
    path: "/ro/privacy-77diamonds",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"ro"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___ru",
    path: "/ru/konfidencial-nost--77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"ru"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___es",
    path: "/es/privacidad-77diamonds",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"es"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___se",
    path: "/se/integritet-77diamonds.html",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"se"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "72_privacy-77diamonds___hu",
    path: "/hu/privacy-77diamonds",
    meta: {"pageId":72,"aspRoute":"/Footer/Privacy.aspx","locale":"hu"},
    component: () => import("/src/pages/privacy-77diamonds.vue")
  },
  {
    name: "754_register___ar",
    path: "/ar/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/ar/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___bg",
    path: "/bg/registraciya",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/bg/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___cs",
    path: "/cs/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/cs/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___dk",
    path: "/dk/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/dk/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___nl",
    path: "/nl/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/nl/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___en",
    path: "/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___fi",
    path: "/fi/rekisteroi",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/fi/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___fr",
    path: "/fr/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/fr/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___de",
    path: "/de/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/de/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___it",
    path: "/it/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/it/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___cn",
    path: "/cn/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/cn/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___no",
    path: "/no/registrer-deg",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/no/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___pl",
    path: "/pl/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/pl/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___pt",
    path: "/pt/registar",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/pt/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___ro",
    path: "/ro/inregistreaza-te",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/ro/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___ru",
    path: "/ru/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/ru/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___es",
    path: "/es/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/es/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___se",
    path: "/se/register",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/se/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "754_register___hu",
    path: "/hu/regisztracio",
    meta: { ...(register9mc1FDQpDSMeta || {}), ...{"pageId":754,"aspRoute":"/NewApp/Default.aspx?app=register","locale":"hu"} },
    alias: ["/hu/NewApp/Default.aspx?app=register"],
    component: () => import("/src/pages/register.vue")
  },
  {
    name: "760_reset-password___ar",
    path: "/ar/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/ar/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___bg",
    path: "/bg/otmyana-na-parolata",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/bg/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___cs",
    path: "/cs/resetovat-heslo",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/cs/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___dk",
    path: "/dk/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/dk/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___nl",
    path: "/nl/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/nl/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___en",
    path: "/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___fi",
    path: "/fi/reset-password-(nollaa-salasana)",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/fi/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___fr",
    path: "/fr/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/fr/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___de",
    path: "/de/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/de/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___it",
    path: "/it/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/it/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___cn",
    path: "/cn/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/cn/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___no",
    path: "/no/-tilbakestill-passord",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/no/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___pl",
    path: "/pl/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/pl/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___pt",
    path: "/pt/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/pt/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___ro",
    path: "/ro/reset-parola",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/ro/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___ru",
    path: "/ru/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/ru/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___es",
    path: "/es/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/es/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___se",
    path: "/se/reset-password",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/se/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "760_reset-password___hu",
    path: "/hu/jelszo-alaphelyzetbe-allitasa",
    meta: { ...(reset_45passwordwGIRYTe8GpMeta || {}), ...{"pageId":760,"aspRoute":"/NewApp/Default.aspx?app=reset-password","locale":"hu"} },
    alias: ["/hu/NewApp/Default.aspx?app=reset-password"],
    component: () => import("/src/pages/reset-password.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___ar",
    path: "/ar/masat-kyubid-almithalia",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"ar"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___bg",
    path: "/bg/cupids-ideal-diamonds",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"bg"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___cs",
    path: "/cs/cupids-idealni-diamanty",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"cs"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___dk",
    path: "/dk/cupids-ideal-diamanter",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"dk"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___nl",
    path: "/nl/cupids-ideal-diamanten",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"nl"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___en",
    path: "/cupids-ideal-diamonds",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"en"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___fi",
    path: "/fi/kupit-ihanteelliset-timantit",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"fi"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___fr",
    path: "/fr/l-ideal-de-cupidon",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"fr"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___de",
    path: "/de/cupids-ideal-diamanten",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"de"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___it",
    path: "/it/diamanti-ideali-di-cupido",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"it"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___cn",
    path: "/cn/qiubite-wanmei-zuanshi",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"cn"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___no",
    path: "/no/cupids-ideelle-diamanter",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"no"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___pl",
    path: "/pl/cupids-ideal-diamonds",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"pl"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___pt",
    path: "/pt/cupids-ideal-diamantes",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"pt"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___ro",
    path: "/ro/cupidele-diamantelor-ideale",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"ro"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___ru",
    path: "/ru/ideal-kupidona-brillianty",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"ru"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___es",
    path: "/es/los-diamantes-ideales-de-cupido",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"es"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___se",
    path: "/se/diamanterna-cupids-ideal",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"se"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "717_seo-pages-cupids-Ideal___hu",
    path: "/hu/cupids-idealis-gyemantok",
    meta: {"pageId":717,"aspRoute":"/SEOPages/CupidsIdeal.aspx","locale":"hu"},
    component: () => import("/src/pages/seo-pages/cupids-Ideal.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___ar",
    path: "/ar/yaqut-jrinland",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"ar"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___bg",
    path: "/bg/zeleni-rubini",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"bg"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___cs",
    path: "/cs/greenland-rubiny",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"cs"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___dk",
    path: "/dk/gronlandske-rubiner",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"dk"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___nl",
    path: "/nl/robijnen-uit-groenland",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"nl"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___en",
    path: "/greenland-rubies",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"en"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___fi",
    path: "/fi/vihrean-maan-rubiinit",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"fi"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___fr",
    path: "/fr/rubis-du-groenland",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"fr"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___de",
    path: "/de/gronland-rubine",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"de"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___it",
    path: "/it/rubini-della-groenlandia",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"it"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___cn",
    path: "/cn/gelinglan-hongbaoshi",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"cn"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___no",
    path: "/no/greenland-rubies",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"no"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___pl",
    path: "/pl/rubiny-grenlandzkie",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"pl"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___pt",
    path: "/pt/greenland-rubies",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"pt"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___ro",
    path: "/ro/greenland-rubine",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"ro"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___ru",
    path: "/ru/grenlandskiye-rubiny",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"ru"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___es",
    path: "/es/rubies-de-groenlandia",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"es"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___se",
    path: "/se/gronlandska-rubiner",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"se"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "713_seo-pages-greenland-rubies___hu",
    path: "/hu/greenland-rubinok",
    meta: {"pageId":713,"aspRoute":"/SEOPages/GreenlandRubies.aspx","locale":"hu"},
    component: () => import("/src/pages/seo-pages/greenland-rubies.vue")
  },
  {
    name: "132_seo-pages-shape___ar",
    path: "/ar/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"ar"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___bg",
    path: "/bg/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"bg"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___cs",
    path: "/cs/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"cs"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___dk",
    path: "/dk/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"dk"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___nl",
    path: "/nl/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"nl"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___en",
    path: "/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"en"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___fi",
    path: "/fi/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"fi"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___fr",
    path: "/fr/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"fr"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___de",
    path: "/de/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"de"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___it",
    path: "/it/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"it"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___cn",
    path: "/cn/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"cn"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___no",
    path: "/no/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"no"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___pl",
    path: "/pl/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"pl"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___pt",
    path: "/pt/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"pt"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___ro",
    path: "/ro/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"ro"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___ru",
    path: "/ru/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"ru"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___es",
    path: "/es/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"es"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___se",
    path: "/se/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"se"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "132_seo-pages-shape___hu",
    path: "/hu/SEOPages/shape.aspx",
    meta: {"pageId":132,"aspRoute":"/SEOPages/shape.aspx","locale":"hu"},
    component: () => import("/src/pages/seo-pages/shape.vue")
  },
  {
    name: "shop-bag___ar",
    path: "/ar/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___bg",
    path: "/bg/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___cs",
    path: "/cs/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___dk",
    path: "/dk/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___nl",
    path: "/nl/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___en",
    path: "/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___fi",
    path: "/fi/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___fr",
    path: "/fr/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___de",
    path: "/de/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___it",
    path: "/it/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___cn",
    path: "/cn/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___no",
    path: "/no/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___pl",
    path: "/pl/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___pt",
    path: "/pt/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___ro",
    path: "/ro/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___ru",
    path: "/ru/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___es",
    path: "/es/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___se",
    path: "/se/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "shop-bag___hu",
    path: "/hu/shop-bag",
    component: () => import("/src/pages/shop-bag.vue")
  },
  {
    name: "727_showroom___ar",
    path: "/ar/salat-alard/mayfir",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___ar",
    path: "/ar/salat-alard/maanshstr",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___ar",
    path: "/ar/salat-alard/faraankfourt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___ar",
    path: "/ar/salat-ard/berline",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___ar",
    path: "/ar/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___ar",
    path: "/ar/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___ar",
    path: "/ar/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___ar",
    path: "/ar/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___ar",
    path: "/ar/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___ar",
    path: "/ar/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___ar",
    path: "/ar/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"ar"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___bg",
    path: "/bg/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___bg",
    path: "/bg/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___bg",
    path: "/bg/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___bg",
    path: "/bg/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___bg",
    path: "/bg/shourum/cyurikh",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___bg",
    path: "/bg/shourum/myunkhen",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___bg",
    path: "/bg/showroom/stokkholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___bg",
    path: "/bg/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___bg",
    path: "/bg/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___bg",
    path: "/bg/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___bg",
    path: "/bg/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"bg"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___cs",
    path: "/cs/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___cs",
    path: "/cs/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___cs",
    path: "/cs/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___cs",
    path: "/cs/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___cs",
    path: "/cs/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___cs",
    path: "/cs/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___cs",
    path: "/cs/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___cs",
    path: "/cs/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___cs",
    path: "/cs/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___cs",
    path: "/cs/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___cs",
    path: "/cs/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"cs"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___dk",
    path: "/dk/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___dk",
    path: "/dk/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___dk",
    path: "/dk/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___dk",
    path: "/dk/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___dk",
    path: "/dk/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___dk",
    path: "/dk/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___dk",
    path: "/dk/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___dk",
    path: "/dk/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___dk",
    path: "/dk/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___dk",
    path: "/dk/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___dk",
    path: "/dk/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"dk"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___nl",
    path: "/nl/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___nl",
    path: "/nl/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___nl",
    path: "/nl/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___nl",
    path: "/nl/showroom/Berlijn",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___nl",
    path: "/nl/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___nl",
    path: "/nl/showroom/munchen",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___nl",
    path: "/nl/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___nl",
    path: "/nl/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___nl",
    path: "/nl/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___nl",
    path: "/nl/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___nl",
    path: "/nl/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"nl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___en",
    path: "/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___en",
    path: "/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___en",
    path: "/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___en",
    path: "/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___en",
    path: "/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___en",
    path: "/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___en",
    path: "/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___en",
    path: "/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___en",
    path: "/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___en",
    path: "/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___en",
    path: "/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"en"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___fi",
    path: "/fi/esittelyhuone/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___fi",
    path: "/fi/nayttelyhuone/manchesterissa",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___fi",
    path: "/fi/nayttelyhuone/frankfurtissa",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___fi",
    path: "/fi/nayttelyhuone/berliini",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___fi",
    path: "/fi/nayttely/urich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___fi",
    path: "/fi/nayttely/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___fi",
    path: "/fi/nayttely/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___fi",
    path: "/fi/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___fi",
    path: "/fi/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___fi",
    path: "/fi/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___fi",
    path: "/fi/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"fi"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___fr",
    path: "/fr/salle-d-exposition/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___fr",
    path: "/fr/salle-d-exposition/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___fr",
    path: "/fr/salle-d-exposition/francfort",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___fr",
    path: "/fr/salle-d-exposition/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___fr",
    path: "/fr/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___fr",
    path: "/fr/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___fr",
    path: "/fr/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___fr",
    path: "/fr/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___fr",
    path: "/fr/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___fr",
    path: "/fr/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___fr",
    path: "/fr/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"fr"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___de",
    path: "/de/ausstellungsraum/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___de",
    path: "/de/ausstellungsraum/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___de",
    path: "/de/ausstellungsraum/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___de",
    path: "/de/ausstellungsraum/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___de",
    path: "/de/ausstellungsraum/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___de",
    path: "/de/ausstellungsraum/munchen",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___de",
    path: "/de/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___de",
    path: "/de/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___de",
    path: "/de/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___de",
    path: "/de/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___de",
    path: "/de/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"de"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___it",
    path: "/it/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___it",
    path: "/it/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___it",
    path: "/it/showroom/francoforte",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___it",
    path: "/it/showroom/berlino",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___it",
    path: "/it/showroom/zurigo",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___it",
    path: "/it/showroom/monaco",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___it",
    path: "/it/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___it",
    path: "/it/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___it",
    path: "/it/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___it",
    path: "/it/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___it",
    path: "/it/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"it"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___cn",
    path: "/cn/zhan-ting/mei-fei-er",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___cn",
    path: "/cn/zhan-ting/man-qie-si-te",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___cn",
    path: "/cn/zhan-ting/fa-lan-ke-fu",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___cn",
    path: "/cn/zhan-ting/bai-lin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___cn",
    path: "/cn/zhan-ting/su-li-shi",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___cn",
    path: "/cn/zhan-ting/mu-ni-hei",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___cn",
    path: "/cn/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___cn",
    path: "/cn/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___cn",
    path: "/cn/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___cn",
    path: "/cn/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___cn",
    path: "/cn/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"cn"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___no",
    path: "/no/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___no",
    path: "/no/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___no",
    path: "/no/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___no",
    path: "/no/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___no",
    path: "/no/showroom/urich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___no",
    path: "/no/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___no",
    path: "/no/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___no",
    path: "/no/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___no",
    path: "/no/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___no",
    path: "/no/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___no",
    path: "/no/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"no"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___pl",
    path: "/pl/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___pl",
    path: "/pl/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___pl",
    path: "/pl/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___pl",
    path: "/pl/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___pl",
    path: "/pl/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___pl",
    path: "/pl/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___pl",
    path: "/pl/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___pl",
    path: "/pl/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___pl",
    path: "/pl/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___pl",
    path: "/pl/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___pl",
    path: "/pl/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"pl"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___pt",
    path: "/pt/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___pt",
    path: "/pt/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___pt",
    path: "/pt/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___pt",
    path: "/pt/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___pt",
    path: "/pt/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___pt",
    path: "/pt/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___pt",
    path: "/pt/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___pt",
    path: "/pt/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___pt",
    path: "/pt/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___pt",
    path: "/pt/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___pt",
    path: "/pt/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"pt"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___ro",
    path: "/ro/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___ro",
    path: "/ro/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___ro",
    path: "/ro/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___ro",
    path: "/ro/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___ro",
    path: "/ro/showroom/urich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___ro",
    path: "/ro/showroom/municipiu",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___ro",
    path: "/ro/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___ro",
    path: "/ro/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___ro",
    path: "/ro/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___ro",
    path: "/ro/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___ro",
    path: "/ro/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"ro"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___ru",
    path: "/ru/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___ru",
    path: "/ru/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___ru",
    path: "/ru/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___ru",
    path: "/ru/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___ru",
    path: "/ru/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___ru",
    path: "/ru/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___ru",
    path: "/ru/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___ru",
    path: "/ru/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___ru",
    path: "/ru/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___ru",
    path: "/ru/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___ru",
    path: "/ru/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"ru"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___es",
    path: "/es/salon-de-exposiciones/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___es",
    path: "/es/salon-de-exposiciones/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___es",
    path: "/es/salon-de-exposiciones/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___es",
    path: "/es/salon-de-exposiciones/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___es",
    path: "/es/salon-de-exposiciones/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___es",
    path: "/es/salon-de-exposiciones/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___es",
    path: "/es/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___es",
    path: "/es/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___es",
    path: "/es/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___es",
    path: "/es/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___es",
    path: "/es/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"es"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___se",
    path: "/se/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___se",
    path: "/se/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___se",
    path: "/se/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___se",
    path: "/se/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___se",
    path: "/se/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___se",
    path: "/se/showroom/munchen",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___se",
    path: "/se/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___se",
    path: "/se/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___se",
    path: "/se/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___se",
    path: "/se/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___se",
    path: "/se/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"se"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "727_showroom___hu",
    path: "/hu/showroom/mayfair",
    meta: {"pageId":727,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=1","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "728_showroom___hu",
    path: "/hu/showroom/manchester",
    meta: {"pageId":728,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=2","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "729_showroom___hu",
    path: "/hu/showroom/frankfurt",
    meta: {"pageId":729,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=3","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "730_showroom___hu",
    path: "/hu/showroom/berlin",
    meta: {"pageId":730,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=4","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "731_showroom___hu",
    path: "/hu/showroom/zurich",
    meta: {"pageId":731,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=5","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "732_showroom___hu",
    path: "/hu/showroom/munich",
    meta: {"pageId":732,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=6","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "733_showroom___hu",
    path: "/hu/showroom/stockholm",
    meta: {"pageId":733,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=7","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "784_showroom___hu",
    path: "/hu/showroom/glasgow",
    meta: {"pageId":784,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=12","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "785_showroom___hu",
    path: "/hu/showroom/dubai",
    meta: {"pageId":785,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=9","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "786_showroom___hu",
    path: "/hu/showroom/geneva",
    meta: {"pageId":786,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=10","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "788_showroom___hu",
    path: "/hu/showroom/milan",
    meta: {"pageId":788,"aspRoute":"/NewApp/Default.aspx?app=showroom&city=11","locale":"hu"},
    component: () => import("/src/pages/showroom.vue")
  },
  {
    name: "726_showrooms___ar",
    path: "/ar/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"ar"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___bg",
    path: "/bg/izlozhbeni-zali",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"bg"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___cs",
    path: "/cs/predvadeci-mistnosti",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"cs"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___dk",
    path: "/dk/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"dk"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___nl",
    path: "/nl/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"nl"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___en",
    path: "/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"en"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___fi",
    path: "/fi/esittelytilat",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"fi"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___fr",
    path: "/fr/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"fr"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___de",
    path: "/de/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"de"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___it",
    path: "/it/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"it"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___cn",
    path: "/cn/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"cn"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___no",
    path: "/no/utstillingslokaler",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"no"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___pl",
    path: "/pl/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"pl"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___pt",
    path: "/pt/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"pt"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___ro",
    path: "/ro/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"ro"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___ru",
    path: "/ru/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"ru"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___es",
    path: "/es/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"es"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___se",
    path: "/se/showrooms",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"se"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "726_showrooms___hu",
    path: "/hu/bemutatotermek",
    meta: {"pageId":726,"aspRoute":"/NewApp/Default.aspx?app=showroom","locale":"hu"},
    component: () => import("/src/pages/showrooms.vue")
  },
  {
    name: "3_success___ar",
    path: "/ar/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"ar"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___bg",
    path: "/bg/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"bg"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___cs",
    path: "/cs/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"cs"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___dk",
    path: "/dk/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"dk"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___nl",
    path: "/nl/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"nl"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___en",
    path: "/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"en"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___fi",
    path: "/fi/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"fi"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___fr",
    path: "/fr/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"fr"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___de",
    path: "/de/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"de"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___it",
    path: "/it/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"it"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___cn",
    path: "/cn/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"cn"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___no",
    path: "/no/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"no"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___pl",
    path: "/pl/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"pl"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___pt",
    path: "/pt/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"pt"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___ro",
    path: "/ro/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"ro"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___ru",
    path: "/ru/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"ru"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___es",
    path: "/es/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"es"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___se",
    path: "/se/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"se"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "3_success___hu",
    path: "/hu/Success.aspx",
    meta: {"pageId":3,"aspRoute":"/Success.aspx","locale":"hu"},
    component: () => import("/src/pages/success.vue")
  },
  {
    name: "75_taxduty___ar",
    path: "/ar/alddaraaib.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"ar"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___bg",
    path: "/bg/taxduty",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"bg"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___cs",
    path: "/cs/danove-poplatky",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"cs"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___dk",
    path: "/dk/told-og-skat.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"dk"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___nl",
    path: "/nl/belasting.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"nl"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___en",
    path: "/taxduty.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"en"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___fi",
    path: "/fi/taxduty",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"fi"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___fr",
    path: "/fr/droit-de-douane.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"fr"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___de",
    path: "/de/steuern-und-abgaben.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"de"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___it",
    path: "/it/tasseimposte.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"it"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___cn",
    path: "/cn/shui-fei.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"cn"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___no",
    path: "/no/skatteplikt",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"no"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___pl",
    path: "/pl/podatekclo.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"pl"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___pt",
    path: "/pt/taxduty.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"pt"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___ro",
    path: "/ro/taxduty",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"ro"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___ru",
    path: "/ru/nalogovaja_poshlina.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"ru"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___es",
    path: "/es/impuesto-fiscal",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"es"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___se",
    path: "/se/skatt-tull.html",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"se"},
    component: () => import("/src/pages/taxduty.vue")
  },
  {
    name: "75_taxduty___hu",
    path: "/hu/taxduty",
    meta: {"pageId":75,"aspRoute":"/Footer/Taxduty.aspx","locale":"hu"},
    component: () => import("/src/pages/taxduty.vue")
  }
]