import { useSetupTranslations } from '~/composables/useSetupTranslations'

export default defineNuxtRouteMiddleware(async (to) => {
  const languageCode = getLanguageCodeFromRoute(to)

  const pageId = to.meta.pageId // new shop routes do not have item id,  shop translations come with common translations

  if (!pageId) {
    console.error('Impossible to get translations: pageId not found in route meta.', to.path)
    return
  }

  const { setupTranslationsByPageId } = await useSetupTranslations()

  await setupTranslationsByPageId(pageId, languageCode)
})
